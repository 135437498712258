import { callRESTThrottled } from '../../utils';
import { dispatcher, store } from '../';

export const config = {
  loadApplications(SN) {
    return function (dispatch) {
      return new Promise((resolve,reject)=>{
        callRESTThrottled('POST', '/config/serve', {
          action: 'getTableItems',
          table: 'App',
          extraParams: {
            'ExpressionAttributeValues': {
              ':sn': SN.concat('_current'),
            },
            KeyConditionExpression: 'SN = :sn',
          },
        }).then(r => { resolve(r); });
      });
    };
  },
  getCurrentFaultConfigAll(sns) {
    return function (dispatch) {
      return new Promise(async (resolve,reject)=>{
        const configs = await callRESTThrottled('POST', '/config/serve', {
          action: 'getTableItems',
          table: 'Config_Active',
          extraParams: {
            'ScanIndexForward': false,
            'ExpressionAttributeValues': {
              ':desc': 'Fault Config',
            },
            KeyConditionExpression: 'Description = :desc',
            filter: {
              operation: 'IN',
              attr: 'SN',
              arg: sns,
            },
          },
        });
        resolve(configs);
      });
    };
  },
  getStatusConfigForSNs(sns) {
    return function (dispatch) {
      return new Promise((resolve,reject)=>{
        callRESTThrottled('POST', '/config/serve', {
          action: 'getTableItems',
          table: 'Config_Active',
          extraParams: {
            'ScanIndexForward': false,
            'ExpressionAttributeValues': {
              ':desc': 'Status Data Table',
            },
            KeyConditionExpression: 'Description = :desc',
            filter: {
              operation: 'IN',
              attr: 'SN',
              arg: sns,
            }
          },
        }).then(r => {
          const newStatusConfigDict = r.result.reduce((acc, cur) => {
            acc[cur.SN] = cur.DataFormat || null;
            return acc;
          }, store.getState().site.statusConfigDict);

          dispatch(dispatcher('UPDATE_SITE_MODULE', { statusConfigDict: newStatusConfigDict }));
        });
      });
    };
  },
  getApplication(SN,ID) {
    return function (dispatch) {
      return new Promise((resolve,reject)=>{
        callRESTThrottled('POST', '/config/serve', {
          action: 'getTableItems',
          table: 'App',
          extraParams: {
            'ScanIndexForward': false,
            'ExpressionAttributeValues': {
              ':v': SN.concat('_current'),
              ':appid': ID,
            },
            KeyConditionExpression: 'SN = :v',
            FilterExpression: 'AppId = :appid',
          },
        }).then(r => {
          if(r.result[0]){
            r.result[0].SN = r.result[0].SN.replace('_current','');
          }
          resolve(r);
        });
      });
    };
  },
  saveApplication(SN, item){
    return function(dispatch){
      return new Promise((resolve)=>{
        callRESTThrottled('POST',
          '/config/serve',
          {
            action: 'saveApplication',
            item: item,
            topicPrefix: store.getState().site.siteMeta.TopicPrefix,
            sn: SN,
          }
        ).then((r)=>{
          resolve(r);
        });
      });
    };
  },
  loadStacks(SN) {
    return function (dispatch) {
      return new Promise((resolve,reject)=>{
        callRESTThrottled('POST', '/config/serve', {
          action: 'getTableItems',
          table: 'Stack',
          extraParams: {
            'ExpressionAttributeValues': {
              ':sn': SN.concat('_current'),
            },
            KeyConditionExpression: 'SN = :sn',
          },
        }).then(r => {
          const res = r.result.filter((stack)=>{
            if(stack.status === 'removed'){ return false; }
            return true;
          });
          const stackList = res.reduce((obj, item) => {
            obj[item.Id] = item.Name;
            return obj;
          }, {});

          dispatch({type:'SET_STACK_FROM_DB', stacks:stackList});
          resolve(r);
        });
      });
    };
  },
  getStack(SN, ID) {
    return function (dispatch) {
      return new Promise((resolve,reject)=>{
        callRESTThrottled('POST', '/config/serve', {
          action: 'getTableItems',
          table: 'Stack',
          extraParams: {
            'ExpressionAttributeValues': {
              ':sn': SN.concat('_current'),
              ':id': ID,
            },
            KeyConditionExpression: 'SN = :sn AND TS = :id',
          },
        }).then(r => {
          if(r.result[0]){
            r.result[0].SN = r.result[0].SN.replace('_current','');
          }
          resolve(r);
        });
      });
    };
  },
  saveStack(SN, item) {
    return function(dispatch){
      dispatch(dispatcher('LOADING', { saveStack: true }));
      return new Promise((resolve)=>{
        callRESTThrottled('POST',
          '/config/serve',
          {
            action: 'saveStack',
            item: item,
            topicPrefix: store.getState().site.siteMeta.TopicPrefix,
            sn: SN,
          }
        ).then((r)=>{
          dispatch(dispatcher('LOADING', { saveStack: false }));
          resolve(r);
        });
      });
    };
  },
  getRackAlarmMapConfig() {
    return function (dispatch) {
      return new Promise((resolve,reject)=>{
        callRESTThrottled('POST', '/config/serve', {
          action: 'getTableItems',
          table: 'Config_Active',
          extraParams: {
            'ScanIndexForward': false,
            'ExpressionAttributeValues': {
              ':desc': 'Rack Alarm Map',
              ':sn': store.getState().site.siteMeta.SN,
            },
            KeyConditionExpression: 'Description = :desc AND SN = :sn',
          },
        }).then(r => {
          dispatch(dispatcher('UPDATE_DEVICES_MODULE', { rackAlarmMap:
            (r.result && r.result[0] && r.result[0].Map) ? r.result[0].Map : []
          }));
        });
      });
    };
  },
};