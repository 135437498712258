import React, { Component } from 'react';
import './SiteUnitComandsPopup.scoped.scss';
import { storeConnector } from '../../../../store';
import Input from '../../../elements/Input/Input';
import { tsDueTimeMode } from '../../../../utils';
import Draggable from 'react-draggable';

class SiteUnitComandsPopup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mode: '',
      reactive: 0,
      real: 0,
      unit: '',
      allowedMinReal: 0,
      allowedMaxReal: 0,
      allowedMaxReactive: 0,
      allowedMinReactive: 0,
      showConfirmationPopup: false,
      confirmationPopupChecklist: false,
      commands: [],
      itemsSelected: [],
      multiselectItems: [],
      afterSelectConfirmDlg: false,
      lotoPopupIsOpen: false,
      lotoReason: '',
      lotoConfirm: false,
      lotoReleaseActive: false,
      lotoReleaseNotes: '',
      lotoReleaseConfirm: false,
    };
    this.setWrapperRefFirst = this.setWrapperRefFirst.bind(this);
    this.setWrapperRefSecond = this.setWrapperRefSecond.bind(this);
  }

  setWrapperRefFirst(node) {
    this.inputFirstRef = node;
  }
  setWrapperRefSecond(node) {
    this.inputSecondRef = node;
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (!this.props.isShownComandsPopup && nextProps.isShownComandsPopup) {
      let newState = {
        real: +nextProps.popupProps.obj.Pset || 0,
        reactive: +nextProps.popupProps.obj.Qset || 0,
        allowedMinReal: nextProps.popupProps.obj.allowedMinReal ||
          +nextProps.popupProps.obj.AllowedMinP || -3,
        allowedMaxReal: nextProps.popupProps.obj.allowedMaxReal ||
          +nextProps.popupProps.obj.AllowedMaxP || 10,
        allowedMinReactive: nextProps.popupProps.obj.allowedMinReactive ||
          +nextProps.popupProps.obj.AllowedMinQ || -3,
        allowedMaxReactive: nextProps.popupProps.obj.allowedMaxReactive ||
          +nextProps.popupProps.obj.AllowedMaxQ || 10,
        mode: '',
        showConfirmationPopup: false,
      };
      if(nextProps.popupProps.popupType === 'unit'){
        newState.mode = nextProps.popupProps.obj['RunMode'] || nextProps.popupProps.obj['Run Mode'];
        newState.unit = nextProps.popupProps.obj.UnitName;

      }else if(nextProps.popupProps.popupType === 'site'){
        newState.mode = nextProps.popupProps.obj.LocRemCtl || 'Auto';
      }
      this.setState(newState);
    }
    return true;
  }

  titleMultiselectDlg(commandName){
    return `Choose ${this.props.popupProps.popupType === 'site' ? 'units': 'racks'}\
    to execute command ${commandName ? commandName : this.state.commands[0]}.
    `;
  }

  rackConfirmStr() {
    let str = ' for ' + (this.props.popupProps.popupType === 'site' ? 'unit': 'rack');
    if (this.state.itemsSelected.filter(el => !!el).length > 1) {
      str+='s';
    }
    this.state.multiselectItems.forEach((r,i) => {
      if (this.state.itemsSelected[i] && this.state.itemsSelected[i] !== 'disabled') {
        str += ` ${r},`;
      }
    });
    str = str.slice(0,-1);
    return str;

  }
  async showConfPopup(com, type, isMulti){
    const newState = {
      showConfirmationPopup: true,
      commands: [com],
      type: type,
      confirmationPopupChecklist: !!isMulti
    };
    const forSite = this.props.popupProps.popupType === 'site';
    if (isMulti && !forSite) {
      this.setState({loading: true});
      const sn = this.props.UnitTable.find(u => u.UnitName === this.state.unit).SN;
      const rackObj = await this.props.actions.getRack({SN: sn}, true);
      const arr = rackObj.RackMode.replace(/(\[|\])/g,'').split(',');
      newState.multiselectItems = arr.map((e,index)=>
        (index < 9?'0':'') + (index+1)
      );
      if (['Rack Open', 'Rack Close'].includes(com)) {
        newState.itemsSelected =
          arr.map(el => { return (com === 'Rack Open' ? el === 0 : el !== 0); });
      } else {
        newState.itemsSelected = Array(arr.length).fill(false);
      }
    }
    if(isMulti && forSite) {
      this.setState({loading: true});
      const arr = this.props.UnitTable.map(u => u.UnitName);
      newState.multiselectItems = arr;
      if (com === 'Stop Units') {
        newState.itemsSelected = this.props.UnitTable.map(el => {
          return !(['RunPQ', 'RunUF'].includes(el['InvStatus']) || ['RunPQ', 'RunUF']
            .includes(el['Inv Status']));
        });
      }
      if (com === 'Start Units') {
        newState.itemsSelected = this.props.UnitTable.map(el => {
          return (['RunPQ', 'RunUF'].includes(el['InvStatus']) || ['RunPQ', 'RunUF']
            .includes(el['Inv Status']));
        });
      }
      if (com === 'Units to Manual') {
        newState.itemsSelected = this.props.UnitTable.map(el => {
          return [el['RunMode'], el['Run Mode']].includes('Manual');
        });
      }
      if (com === 'Units to Auto') {
        newState.itemsSelected = this.props.UnitTable.map(el => {
          return [el['RunMode'], el['Run Mode']].includes('Auto');
        });
      }
      if (com === 'Open AC Breakers') {
        newState.itemsSelected = this.props.UnitTable.map(el => {
          return [el['ACBreaker'], el['AC Breaker']].includes('0');
        });
      }
      if (com === 'Close AC Breakers') {
        newState.itemsSelected = this.props.UnitTable.map(el => {
          return ![el['ACBreaker'], el['AC Breaker']].includes('0');
        });
      }
      if (![
        'Stop Units',
        'Start Units',
        'Units to Manual',
        'Units to Auto',
        'Open AC Breakers',
        'Close AC Breakers',
      ].includes(com) || !newState.itemsSelected) {
        newState.itemsSelected = Array(this.props.UnitTable.length).fill(false);
      }
    }
    newState.loading = false;
    this.setState(newState);
  }
  changeMode(e) {
    this.showConfPopup(`System Mode::${e.target.value}`, 'mode');
  }
  replaceNulls(unitName){
    return unitName.replace(/^0+/, '');
  }
  onChangeReal(value) {
    this.setState({
      real: value
    });
  }
  submitPopup(){
    if (this.state.afterSelectConfirmDlg && this.state.confirmationPopupChecklist) {
      if(this.props.popupProps.popupType === 'site') {
        this.props.UnitTable.forEach((el,i) => {
          if (this.state.itemsSelected[i] === true) {
            this.props.actions.sendCommand(this.state.commands, el.SN);
          }
        });
      } else {
        this.props.actions.sendCommand(
          [`${this.state.commands[0]}::[${this.state.itemsSelected.map((el, i) =>
            (el && el !== 'disabled') ? i : '').filter(e => e !== '').join(',')}]`],
          this.props.popupProps.obj.SN || this.props.popupProps.obj.SN
        );
      }
      this.setState({
        afterSelectConfirmDlg: false,
        confirmationPopupChecklist: false,
        showConfirmationPopup: false,
        commands: [],
        multiselectItems: [],
        itemsSelected: []
      });
    } else {
      if (this.state.confirmationPopupChecklist){
        return;
      }
      this.props.actions.sendCommand(this.state.commands,
        this.props.popupProps.obj.SN || this.props.popupProps.obj.SN
      );
    }
    if (this.state.type === 'mode') {
      const idx = this.state.commands[0].lastIndexOf(':');
      this.setState({mode: this.state.commands[0].substring(idx+1)});
    }
  }
  onChangeReactive(value) {
    this.setState({
      reactive: value
    });
  }
  onEnter(key) {
    if (key === 0) {
      this.inputSecondRef.focus();
    }
    if (key === 1) {
      if (this.state.real !== '' &&
          this.state.reactive !== '' &&
          this.state.real > this.state.allowedMinReal &&
          this.state.real < this.state.allowedMaxReal &&
          this.state.reactive > this.state.allowedMinReactive &&
          this.state.reactive < this.state.allowedMaxReactive
      ) {

        this.showConfPopup(`Power::${this.state.real}::${this.state.reactive}`,'value');
      } else {
        if (this.state.real !== '' && this.state.real > this.state.allowedMinReal &&
        this.state.real < this.state.allowedMaxReal) {
          this.inputSecondRef.focus();
        } else {
          this.inputFirstRef.focus();
        }
      }
    }
  }

  commandsList() {
    if (this.props.siteMeta && this.props.popupProps) {
      const siteCommandsIsArray = Array.isArray(this.props.siteMeta.Site_Commands);
      const unitCommandsIsArray = Array.isArray(this.props.siteMeta.Unit_Commands);
      let unitCommandsArray = [];

      if(unitCommandsIsArray) {
        unitCommandsArray = this.props.siteMeta.Unit_Commands.map(k => {
          return {
            label: k.Name,
            value: k.Command,
            multiselect: false,
          };
        });
      } else {
        unitCommandsArray = (this.props.siteMeta.Unit_Commands ?
          Object.keys(this.props.siteMeta.Unit_Commands).map(k => {
            return {
              label: k,
              value: this.props.siteMeta.Unit_Commands[k],
              multiselect: false,
            };
          }) :
          []);
      }

      if (this.props.popupProps.popupType === 'site') {
        if(siteCommandsIsArray) {
          return this.props.siteMeta.Site_Commands.map(k => {
            return {
              label: k.Name,
              value: k.Command,
              multiselect: k.Device === 'Unit',
            };
          });
        } else {
          return this.props.siteMeta.Site_Commands ?
            Object.keys(this.props.siteMeta.Site_Commands).map(k => {
              return {
                label: k,
                value: this.props.siteMeta.Site_Commands[k],
                multiselect: k !== 'Fault Reset',
              };
            }) :
            [];
        }
      } else {
        return [
          ...unitCommandsArray,
          ...(this.props.siteMeta.Rack_Commands ?
            Object.keys(this.props.siteMeta.Rack_Commands).map(k => {
              return {
                label: k,
                value: this.props.siteMeta.Rack_Commands[k],
                multiselect: true,
              };
            }) :
            []
          ),
        ];
      }
    }
    return [];
  }
  isUnitRunned() {
    if (this.props.popupProps.popupType === 'unit') {
      const unit = this.props.UnitTable.find(u => u.UnitName === this.state.unit);
      if (unit) {
        const invStatus = unit['InvStatus'] || unit['Inv Status'];
        const battery = this.props.batteryTable[unit.SN];
        let racksOnline = 0;
        let racks = 0;
        if (battery && battery.ConnectingStatus && battery.ConnectingStatus.length === 4) {
          racksOnline = parseInt(battery.ConnectingStatus.substr(0,2),16);
          racks = parseInt(battery.ConnectingStatus.substr(2),16);
        }
        const isStopped = ['Ready', 'Off'].includes(invStatus) && unit.LocRemCtl === 'Manual' &&
          racks === racksOnline;
        return !isStopped;
      }
      return false;
    }
    return false;
  }

  confirmLOTO(tagout){
    const unit = this.props.UnitTable.find(u => u.UnitName === this.state.unit);
    if (unit) {
      this.setState({[tagout ? 'lotoReleaseConfirm' : 'lotoConfirm']: false}, async() => {
        await this.props.actions.setLOTO({
          SiteSN: this.props.siteMeta.SN,
          UnitSN: unit.SN,
          Note: tagout ? this.state.lotoReleaseNotes : this.state.lotoReason,
          mode: tagout ? 'tagout' : 'lockout',
        });
        this.setState({lotoReleaseNotes: '', lotoReason: ''});
      });
    }
  }

  getLoto() {
    if (this.props.isShownComandsPopup && this.props.popupProps.popupType === 'unit') {
      const unit = this.props.UnitTable.find(u => u.UnitName === this.state.unit);
      if (unit) {
        const loto = this.props.lotoTable[unit.SN];
        if (loto) {
          return loto;
        }
        return false;
      }
      return false;
    }
    return false;
  }

  getCurrentTime(ts) {
    return tsDueTimeMode({
      timeMode: this.props.timeMode,
      lat: this.props.siteMeta.GPSLat,
      long: this.props.siteMeta.GPSLong,
      ts,
    });
  }

  render() {
    let commands = this.commandsList();
    const siteCommandsIsArray = Array.isArray(this.props.siteMeta?.Site_Commands);
    const unitCommandsIsArray = Array.isArray(this.props.siteMeta?.Unit_Commands);
    let allCommandsList;
    if(siteCommandsIsArray && unitCommandsIsArray) {
      allCommandsList = this.props.siteMeta?.Site_Commands
        .concat(this.props.siteMeta?.Unit_Commands);
    }
    const commandName = allCommandsList?.find(el => el.Command === this.state.commands[0])?.Name;
    const loto = this.getLoto();
    return (
      this.props.isShownComandsPopup &&
      <div className='cover-conteiner'>
        <Draggable bounds='body'>
          <div className='control-popup-container' style={{minWidth:'540px', minHeight:'400px'}}>
            {
              this.state.loading &&
            <div className='spinner-holder'>
              <div className='lds-ripple'><div /><div /></div>
            </div>
            }
            {
              (this.props.popupProps.popupType === 'site' &&
              <div className='title-line'>Site Commands</div>) ||
              (this.props.popupProps.popupType === 'unit' && this.state.unit &&
              <div className='title-line'>Unit #{this.replaceNulls(this.state.unit)} Commands</div>)
            }
            <div className='popup-close'
              onClick={() => this.props.actions.showComandsPopup(false, {})}
            />
            <div className='commands-popup-content'>
              {
                loto ?
                  <div className='release-container'>
                    {
                      !this.state.lotoReleaseActive ?
                        <>
                          <button
                            onClick={()=>this.setState({lotoReleaseActive: true})}
                          >
                        Release Controls LOTO
                          </button>
                        </>
                        :
                        <>
                          <div className='loto-release-title'>
                        Release Controls LOTO
                          </div>
                          <div className='loto-release-info'>
                            <div>LOTO Start:</div>
                            <div>{loto.TS ? this.getCurrentTime(loto.TS) : ''}</div>
                          </div>
                          <div className='loto-release-info'>
                            <div>Operator:</div>
                            <div>{loto.Operator}</div>
                          </div>
                          <div className='loto-release-info'>
                            <div>Notes:</div>
                            <div>{loto.Note}</div>
                          </div>
                          <div className='loto-release-info'>
                            <div>Release Notes:</div>
                            <Input
                              type='text'
                              value={this.state.lotoReleaseNotes}
                              onChange={(val)=>this.setState({ lotoReleaseNotes: val })}
                              placeholder={'Enter reason'}
                            />
                          </div>
                          <div className='popup-row commands-popup-control'
                            style={{marginTop: '50px'}}
                          >
                            <button disabled={!this.state.lotoReleaseNotes} className='bold'
                              onClick={() => this.setState({lotoReleaseConfirm: true})}
                            >
                          TAGOUT
                            </button>
                            <button className='bold'
                              onClick={() => this.setState({lotoReleaseActive: false})}
                            >CANCEL</button>
                          </div>
                        </>
                    }
                  </div>
                  :
                  <>
                    <div className='popup-content-col'>
                      <div className='popup-row mode' onChange={(e) => this.changeMode(e)}>
                        <div className='bold'>Mode</div>
                        <div className='comand-popup-row'>
                          <label className='inp-container' htmlFor='popupModeA'>
                            <input
                              type='radio'
                              value='Auto'
                              id='popupModeA'
                              checked={this.state.mode === 'Auto'}
                              name='mode'
                            />
                            <div>Auto</div>
                          </label>
                          <label className='inp-container' htmlFor='popupModeM'>
                            <input
                              type='radio'
                              value='Manual'
                              id='popupModeM'
                              name='mode'
                              checked={this.state.mode === 'Manual'}
                            />
                            <div>Manual</div>
                          </label>
                          {
                            this.props.popupProps.popupType === 'unit' && this.state.unit &&
                            <label className='inp-container' htmlFor='popupModeT'>
                              <input
                                type='radio'
                                value='Target SOC'
                                id='popupModeT'
                                name='mode'
                                checked={this.state.mode === 'Target SOC'}
                              />
                              <div>Target SOC</div>
                            </label>
                          }
                        </div>
                      </div>
                      {
                        this.state.mode === 'Manual' &&
                    <div className='popup-row'>
                      <div className='bold'>Power</div>
                      <div className='popup-table'>
                        <div className='popup-table-col'>
                          <div>Real (P):</div>
                          <div className='range-lable' />
                          <div>Reactive (Q):</div>
                          <div className='range-lable' />
                        </div>
                        <div className='popup-table-col'>
                          <div>
                            <input ref={this.setWrapperRefFirst}
                              onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                  this.onEnter(0);
                                  e.preventDefault();
                                }
                              }}
                              className={'comand-popup-inp '+
                              (this.state.real<this.state.allowedMinReal?
                                'error':(this.state.real>this.state.allowedMaxReal?'error':''))}
                              type='number' step='0.1' value={this.state.real}
                              onChange={e => this.onChangeReal(e.target.value)}
                            />
                          </div>
                          <div className='range-lable'>
                            Range from {this.state.allowedMinReal} to {this.state.allowedMaxReal}
                          </div>
                          <div>
                            <input ref={this.setWrapperRefSecond}
                              onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                  this.onEnter(1);
                                  e.preventDefault();
                                }
                              }}
                              className={'comand-popup-inp '+
                                (this.state.reactive<this.state.allowedMinReactive? 'error':
                                  (this.state.reactive>this.state.allowedMaxReactive?'error':''))
                              }
                              type='number' step='0.1' value={this.state.reactive}
                              onChange={e => this.onChangeReactive(e.target.value,'reactive')}
                            />
                          </div>
                          <div className='range-lable'>
                            Range from {this.state.allowedMinReactive} to&nbsp;
                            {this.state.allowedMaxReactive}
                          </div>
                        </div>
                        <div className='popup-table-col'>
                          <div>kW</div>
                          <div className='range-lable' />
                          <div>kvar</div>
                          <div className='range-lable' />
                        </div>
                      </div>
                    </div>
                      }
                      { this.state.mode === 'Manual' &&
                      (this.state.real<this.state.allowedMinReal||
                      this.state.real>this.state.allowedMaxReal) &&
                      <div className='popup-row warn-alert'>
                        The value of real power must be between {this.state.allowedMinReal
                        } and {this.state.allowedMaxReal}
                      </div>}
                      { this.state.mode === 'Manual' &&
                      (this.state.reactive<this.state.allowedMinReactive||
                      this.state.reactive>this.state.allowedMaxReactive) &&
                      <div className='popup-row warn-alert'>
                        The value of reactive power must be between {this.state.allowedMinReactive
                        } and {this.state.allowedMaxReactive}
                      </div>
                      }
                      {this.state.mode === 'Manual' &&
                    <div className='popup-row commands-popup-control'>
                      <button className='bold' disabled={
                        this.state.real===''||
                        this.state.reactive===''||
                        this.state.real<this.state.allowedMinReal||
                        this.state.real>this.state.allowedMaxReal||
                        this.state.reactive<this.state.allowedMinReactive||
                        this.state.reactive>this.state.allowedMaxReactive
                      }
                      onClick={() => this.showConfPopup(
                        `Power::${this.state.real}::${this.state.reactive}`,
                        'value'
                      )}
                      >Send</button>
                    </div>
                      }
                    </div>
                    {
                      commands.length ?
                        <div className='popup-content-col comands popup-row'>
                          <div className='bold'>Commands</div>
                          <div className='popup-commands'>
                            {
                              commands.map((com, i) =>
                                <button key={i} onClick={() =>
                                  this.showConfPopup(com.value,'status',com.multiselect)}
                                >{com.label}</button>
                              )
                            }
                            {
                              this.props.popupProps.popupType === 'unit' &&
                        <button
                          onClick={()=>this.setState({lotoPopupIsOpen: true})}
                        >
                          Controls LOTO
                        </button>
                            }
                          </div>
                        </div>
                        : <></>
                    }
                  </>
              }
            </div>
          </div>
        </Draggable>
        {
          this.state.lotoConfirm &&
          <div className='cover-conteiner cover-container-confirm'>
            <Draggable bounds='body'>
              <div className='control-popup-container' style={{padding: '20px 30px'}}>
                <div className='popup-row centre'>
                  Unit locked out by {this.props.user.username}
                </div>
                <div className='popup-row commands-popup-control'>
                  <button disabled={!this.state.lotoReason} className='bold'
                    onClick={() => this.confirmLOTO()}
                  >
                    OK
                  </button>
                  <button className='bold'
                    onClick={() => this.setState({lotoConfirm: false, lotoReason: ''})}
                  >CANCEL</button>
                </div>
              </div>
            </Draggable>
          </div>
        }
        {
          this.state.lotoReleaseConfirm &&
          <div className='cover-conteiner cover-container-confirm'>
            <Draggable bounds='body'>
              <div className='control-popup-container' style={{padding: '20px 30px'}}>
                <div className='popup-row centre'>
                  Controls LOTO released by {this.props.user.username}
                </div>
                <div className='popup-row commands-popup-control'>
                  <button className='bold'
                    onClick={() => this.confirmLOTO(true)}
                  >
                    OK
                  </button>
                  <button className='bold'
                    onClick={() => this.setState({lotoReleaseConfirm: false, lotoReleaseNotes: ''})}
                  >CANCEL</button>
                </div>
              </div>
            </Draggable>
          </div>
        }
        {
          this.state.lotoPopupIsOpen &&
          <div className='cover-conteiner cover-container-confirm'>
            <Draggable bounds='body'>
              <div className='control-popup-container' style={{padding: '20px 30px'}}>
                {
                  !this.isUnitRunned() ?
                    <div>
                      <div>
                      Please ensure unit is stopped and all DC Contactors are OPEN prior to LOTO
                      </div>
                      <div className='popup-row commands-popup-control'>
                        <button className='bold'
                          onClick={() => {
                            this.setState({lotoPopupIsOpen: false});
                          }}
                        >OK</button>
                      </div>
                    </div>
                    :
                    <div>
                      <div className='loto-reason-row'>
                        <span>Reason for LOTO</span>
                        <Input
                          type='text'
                          value={this.state.lotoReason}
                          onChange={(val)=>this.setState({ lotoReason: val })}
                          placeholder={'Enter reason'}
                        />
                      </div>
                      <div className='popup-row commands-popup-control'>
                        <button disabled={!this.state.lotoReason} className='bold'
                          onClick={() => this.setState({lotoConfirm: true, lotoPopupIsOpen: false})}
                        >
                        LOCKOUT
                        </button>
                        <button className='bold'
                          onClick={() => this.setState({lotoReason: '', lotoPopupIsOpen: false})}
                        >CANCEL</button>
                      </div>
                    </div>
                }
              </div>
            </Draggable>
          </div>
        }
        {
          this.state.showConfirmationPopup &&
          <div className='cover-conteiner cover-container-confirm'>
            {
              this.state.confirmationPopupChecklist && !this.state.afterSelectConfirmDlg ?
                <Draggable bounds='body'>
                  <div className='control-popup-container' style={{padding: '20px 30px'}}>
                    <div className='title-line' style={{textAlign:'center'}}>
                      {this.titleMultiselectDlg(commandName)}
                    </div>
                    { this.state.multiselectItems.length !== 0 ?
                      <div className='rack-choose-list'>
                        <div id='first-cell'>
                          <button
                            disabled = {!this.state.itemsSelected.includes(false)}
                            onClick={() => {
                              this.setState({
                                itemsSelected: this.state.itemsSelected.map(el => true)
                              });
                            }}>Select All</button>
                          <button
                            disabled = {!this.state.itemsSelected.includes(true)}
                            onClick={() => {
                              this.setState({
                                itemsSelected: this.state.itemsSelected.map(el => false)
                              });
                            }}>Unselect All</button>
                        </div>
                        {
                          this.state.multiselectItems.map((r,i) =>
                            <div key={i} className={
                              `item ${this.state.itemsSelected[i] === 'disabled'?'disabled':''}`}
                            onClick={()=>{
                              const newArr = [...this.state.itemsSelected];
                              newArr[i] = !this.state.itemsSelected[i];
                              this.setState({itemsSelected: newArr});
                            }}
                            >
                              <div className={
                                `input-checkbox ${this.state.itemsSelected[i] ? 'act' : ''}`}
                              />
                              <div>
                                {`${this.props.popupProps.popupType === 'site'? 'Unit':'Rack'} ${r}`}
                              </div>
                            </div>
                          )
                        }
                      </div> :
                      <div style={{textAlign: 'center', marginTop: '10px'}}>
                        {`No ${this.props.popupProps.popupType === 'site'?'units':'racks'} online`}
                      </div>
                    }
                    <div className='popup-row commands-popup-control'>
                      <button className='bold' disabled={!this.state.itemsSelected.includes(true)}
                        onClick={() => {
                          this.submitPopup();
                          this.setState({afterSelectConfirmDlg: true});
                        }}>OK</button>
                      <button className='bold'
                        onClick={() => {
                          this.setState({
                            showConfirmationPopup: false,
                            commands: [],
                            multiselectItems: [],
                            itemsSelected: []
                          });
                        }}
                      >CANCEL</button>
                    </div>
                  </div>
                </Draggable>
                :
                <Draggable bounds='body'>
                  <div className='control-popup-container small-popup'>
                    <div className='popup-row centre'>
                      {
                        /*(this.props.popupProps.popupType === "unit" &&
                  ['Open AC Breaker', 'Open DC Contactors (All)'].includes(this.state.commands[0])
                  && !['Off', 'Fault'].includes(this.state.unit['InvStatus'])) ?
                  <>
                    <div className="bold">Opening of AC Breakers or DC Contactors is NOT ALLOWED while unit is running <br/> Shutdown the PCS prior to executing this command</div>
                  </>
                  :*/
                        <>
                          <div className='bold '>Are you sure to execute?</div>
                          <div className='normal'>
                            {commandName ? commandName : this.state.commands[0]}
                            {`${this.state.afterSelectConfirmDlg ? (this.rackConfirmStr()) : ''}`}
                          </div>
                        </>
                      }
                    </div>
                    <div className='popup-row commands-popup-control'>
                      {
                        /*!(this.props.popupProps.popupType === "unit" &&
                  ['Open AC Breaker', 'Open DC Contactors (All)'].includes(this.state.commands[0])
                  && !['Off', 'Fault'].includes(this.state.unit['InvStatus'])) &&*/
                        <button className='bold'
                          onClick={() => {
                            this.submitPopup();
                            this.setState({showConfirmationPopup: false});
                          }}
                        >OK</button>
                      }
                      <button className='bold' onClick={() => {
                        this.setState({
                          showConfirmationPopup: false,
                          commands: [],
                          afterSelectConfirmDlg: false,
                          multiselectItems: [],
                          itemsSelected: []
                        });
                      }}>CANCEL</button>
                    </div>
                  </div>
                </Draggable>
            }
          </div>
        }
      </div>
    );
  }
}

export default storeConnector(SiteUnitComandsPopup, {
  service: ['isShownComandsPopup', 'popupProps'],
  devices: ['UnitTable', 'batteryTable','lotoTable'],
  site: ['siteMeta'],
  user: ['user','timeMode'],
});