import React from 'react';
import { Component } from 'react';
import { storeConnector } from 'store';
import styles from './Clock.module.css';
import { optionsSelectTZ, tsDueTimeMode } from 'utils';
import Input from 'components/elements/Input/Input';

class Clock extends Component {

  constructor(props) {
    super(props);
    this.state = {
      renderKey: 0,
    };
  }

  setTimeMode(val) {
    this.props.actions.setTimeMode(val);
  }

  componentDidMount(){
    this.timer = setInterval(() => {
      this.setState({renderKey: Math.random()});
    }, 500);
  }

  getCurrentTime() {
    return tsDueTimeMode({
      timeMode: this.props.timeMode,
      lat: this.props.siteMeta.GPSLat,
      long: this.props.siteMeta.GPSLong,
      splited: true,
    });
  }

  render() {
    const tsArr = this.getCurrentTime();
    return (
      <div className={styles.container}>
        <div className={styles.selectRow}>
          <span>Time Mode:</span>
          <Input
            type='dropdown'
            placeholder='Select Device'
            ddMaxHeight='260'
            value={{value: this.props.timeMode}}
            styleDD={{
              maxWidth: '60px',
              minWidth: '60px',
              marginRight: 'auto',
            }}
            onChange={(val) => this.setTimeMode(optionsSelectTZ[val])}
            options={ optionsSelectTZ }
          />
        </div>
        <div className={styles.time}>
          <div>{tsArr[0]}</div>
          <div>
            <div>{tsArr[1]}</div>
            <div style={{
              height: 5,
              background: 'green',
              borderRadius: 5,
              visibility: this.props.updateFinished ? 'visible' : 'hidden'
            }} />
          </div>
        </div>
      </div>
    );
  }
}

export default storeConnector(Clock, {
  user: ['timeMode'],
  site: ['siteMeta'],
  service: ['updateFinished'],
});