const initState = {
  messages: [],
  alarmsAndWarnings:null,
  alarms: null,
  connectionAlert: false,
  msgKey: 0,
  msgKeySorted: 0,
  messagesObj: {},
  msgServiceDict: {},
  sysMsgsPaused: true,
};

export default (state = initState, action) => {
  const handlers = {
    'ADD_MESSAGE': () => {
      function compare(a,b) {
        if (a.TS < b.TS)
          return 1;
        if (a.TS > b.TS)
          return -1;
        return 0;
      }
      const newState = {...state};
      newState.messages.push(action.message);
      newState.messages.sort(compare);
      return newState;
    },
    'UPDATE_MESSAGES_MODULE': () => {
      delete action.type;
      return {...state, ...action};
    },
  };
  if (!handlers[action.type])
    return state;
  return handlers[action.type]();
};