import { callRESTThrottled } from '../../utils';
import moment from 'moment';
import { dispatcher, store } from '../';
import { log } from 'util';

export const pv = {
  loadSitePV(SN, scale) {
    return async function (dispatch) {
      log('loadSitePV');

      const period = {
        '1h': '1hour',
        '1d': '1day',
        '15m': '15min',
      }[scale.toLocaleLowerCase()];

      const maxlength = {
        '1day': 5,
        '1hour': 120,
        '15min': 480,
      };

      const sitePV = store.getState().site.sitePV;
      let res;

      if (sitePV[period]) {
        res = sitePV[period];
      } else {
        res = await new Promise(async (resolve, reject) => {
          callRESTThrottled('POST', '/config/serve', {
            action: 'getTableItems',
            table: 'PV',
            extraParams: {
              'ScanIndexForward': false,
              'ExpressionAttributeValues': {
                ':v': `${SN}_${period}`,
              },
              'KeyConditionExpression': 'SN = :v',
            },
          }).then((data) => {
            let res = data.result;
            res.splice(maxlength[period], 10000);

            if (res.length < maxlength[period]) {
              const lastmoment = res.length > 0 ? moment(res[res.length - 1].TS) : moment();
              for (let i = maxlength[period] - res.length; i > 0; i--) {
                if (period === '15min') {
                  lastmoment.subtract(15, 'minutes');
                } else if (period === '1hour') {
                  lastmoment.subtract(1, 'hour');
                } else {
                  lastmoment.subtract(1, 'day');
                }

                res.push({
                  PV_kW_perInterval: 0,
                  PV_WS: 0,
                  PV_estimated: 0,
                  TS: lastmoment.format()
                });
              }
            }
            dispatch(dispatcher('UPDATE_SITE_MODULE', {
              sitePV: {
                ...sitePV,
                [period]: res,
              }
            }));
            resolve(res);
          });
        });
      }

      return res;
    };
  },
  clearSitePV() {
    return async function (dispatch) {
      dispatch(dispatcher('UPDATE_SITE_MODULE', { sitePV: {} }));
    };
  }

};