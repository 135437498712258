import { dispatcher } from '../';

export const eventSubscribtion = {
  subscribeToEvent(source, handler) {
    return function (dispatch) {
      dispatch(dispatcher('SUBSCRIBE_TO_EVENT', { source, handler }));
    };
  },
  unsubscribeFromEvent(source, handler) {
    return function (dispatch) {
      dispatch(dispatcher('UNSUBSCRIBE_FROM_EVENT', { source, handler }));
    };
  },
};