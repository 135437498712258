import { callRESTThrottled, dummySite } from 'utils';
import { dispatcher, store } from '../';
import moment from 'moment';
export const site = {
  clearSiteMeta() {
    return function (dispatch) {
      dispatch(dispatcher('UPDATE_SITE_MODULE', { siteMeta: null }));
    };
  },
  loadAllSiteMeta(sns, hideLoading) {
    return async function (dispatch) {
      console.log('loadAllSiteMeta');
      !hideLoading && dispatch(dispatcher('LOADING', { siteMeta: true }));
      console.log('Loading site meta');
      const data = await Promise.all(sns.map(sn => callRESTThrottled('POST', '/config/v2/serve', {
        action: 'getSiteMeta',
        SN: sn
      })));
      !hideLoading && dispatch(dispatcher('LOADING', { siteMeta: false }));
      const siteMetas = data.map(el => el.result);

      const newSiteMetas = siteMetas.map(sm => ({
        ...sm,
        SN: sm.TS,
        TS: sm.TS_date,
      })).filter(sm => sns.includes(sm.SN));
      await Promise.all(
        newSiteMetas.map(siteMeta => {
          return dispatch(dispatcher('UPDATE_VPP_MODULE', {
            VPPsSitesMeta: {
              ...store.getState().vpp.VPPsSitesMeta,
              [siteMeta.SN]: siteMeta
            },
            VPPsSites: {
              ...store.getState().vpp.VPPsSites,
              [siteMeta.SN]: { ...dummySite, SN: siteMeta.SN },
            }
          }));
        })
      );
      const allSns = newSiteMetas.reduce((acc, cv) => [...acc, cv.SN, ...cv.Units], []);
      const alarmsData = await callRESTThrottled(
        'POST',
        '/config/serve',
        {
          action: 'getTableItems',
          table: 'Alarms',
          extraParams: {
            'ScanIndexForward': false,
            'ExpressionAttributeValues': {
              ':v': 'current',
            },
            'KeyConditionExpression': 'SN = :v',
          },
        },
        true,
      );
      const alarmsAndWarningDict = allSns.reduce((acc, sn) => {
        const element = alarmsData.result.find(e => e.TS === sn);

        if (element) {
          element.SN = sn;
          acc[sn] = element.Alarms;
        }

        return acc;
      }, {});
      dispatch(dispatcher('UPDATE_SITE_MODULE', { alarmsForAllSites: alarmsAndWarningDict }));
    };
  },
  getSiteMeta(sitesn, hideLoading, multisite) {
    return function (dispatch) {
      return new Promise(async (resolve) => {
        const prevSiteMeta = store.getState().site.siteMeta;
        const alreadyLoaded = prevSiteMeta && prevSiteMeta.SN === sitesn;
        if (!alreadyLoaded) {
          !hideLoading && dispatch(dispatcher('LOADING', { siteMeta: true }));
          console.log('Loading site meta');
          console.log('======waiting for current siteMeta=======');
          const data = await callRESTThrottled('POST', '/config/v2/serve', {
            action: 'getSiteMeta',
            SN: sitesn,
          });
          !hideLoading && dispatch(dispatcher('LOADING', { siteMeta: false }));
          dispatch(dispatcher('UPDATE_SITE_MODULE', { siteMetaError: null }));
          const siteMeta = data.result;
          if (siteMeta.error) {
            dispatch(dispatcher('UPDATE_SITE_MODULE', { siteMetaError: siteMeta.error }));
            resolve();
          } else {
            siteMeta.SN = siteMeta.TS;
            siteMeta.TS = siteMeta.TS_date;
            console.log('loadSiteMeta DATA::::::::', data);
            console.log('loadSiteMeta current siteMeta');
            if (multisite) {
              if (siteMeta) {
                dispatch(dispatcher('UPDATE_VPP_MODULE', {
                  VPPsSitesMeta: {
                    ...store.getState().vpp.VPPsSitesMeta,
                    [siteMeta.SN]: siteMeta
                  },
                  VPPsSites: {
                    ...store.getState().vpp.VPPsSites,
                    [siteMeta.SN]: data[1].result[0]
                  }
                }));
              }
            } else if (siteMeta) {
              dispatch(dispatcher('UPDATE_SITE_MODULE', { siteMeta }));
              dispatch(dispatcher('UPDATE_DEVICES_MODULE', {
                currentUnit: false,
                currentPCS: false,
                currentRack: false
              }));
              const allSns = [siteMeta.SN, ...siteMeta.Units];
              const alarmsData = await callRESTThrottled(
                'POST',
                '/config/serve',
                {
                  action: 'getTableItems',
                  table: 'Alarms',
                  extraParams: {
                    'ScanIndexForward': false,
                    'ExpressionAttributeValues': {
                      ':v': 'current',
                    },
                    'KeyConditionExpression': 'SN = :v',
                  },
                },
                true,
              );
              const alarmsAndWarningDict = allSns.reduce((acc, sn) => {
                const element = alarmsData.result.find(e => e.TS === sn);

                if (element) {
                  element.SN = sn;
                  acc[sn] = element;
                }

                return acc;
              }, {});
              dispatch(dispatcher('UPDATE_MESSAGES_MODULE', { alarms: alarmsAndWarningDict }));
            }
            // createIotClient().then((client) => {
            resolve();
            // });

          }
        } else {
          resolve();
        }
      });
    };
  },

  clearCurrentSite() {
    return function (dispatch) {
      dispatch(dispatcher('UPDATE_DEVICES_MODULE', {
        UnitTable: [],
        unitTaleLoadedFromDynamo: false
      }));
      dispatch(dispatcher('UPDATE_SITE_MODULE', { siteMeta: null, currentSite: null }));
      dispatch(dispatcher('UPDATE_MESSAGES_MODULE', { alarms: null }));
    };
  },

  loadSiteStatus(sitesn) {
    return function (dispatch) {
      callRESTThrottled(
        'POST',
        '/config/serve',
        {
          action: 'getTableItems',
          table: 'Faults',
          totalLimit: 1,
          extraParams: {
            'ScanIndexForward': false,
            'ExpressionAttributeValues': {
              ':v': sitesn,
            },
            'KeyConditionExpression': 'SN = :v',
          },
        }
      ).then((siteStatuses) => {
        dispatch(dispatcher('UPDATE_SITE_MODULE', { siteStatus: siteStatuses.result[0] }));
      });
    };
  },

  loadSiteData(sitesn, duration, tableName, projectionExpression, propsField) {
    return function (dispatch) {
      let projectionExpressionAliases;
      let ExpressionAttributeNames;
      if (projectionExpression && projectionExpression.length !== 0) {
        projectionExpressionAliases = '';
        ExpressionAttributeNames = {};
        projectionExpression.forEach(element => {
          projectionExpressionAliases += '#Attribute_' + element + ',';
          ExpressionAttributeNames['#Attribute_' + element] = element;
        });
        projectionExpressionAliases = projectionExpressionAliases
          .substring(0, projectionExpressionAliases.length - 1);
      }
      return new Promise((resolve) => {
        const value = duration.substring(0, duration.length - 1);
        const curUnit = duration.substring(duration.length - 1, duration.length);
        const unit = {
          M: 'minutes',
          H: 'hours',
          D: 'days',
        }[curUnit];
        if (curUnit === 'D') {
          const tasks = [];
          for (let day = 0; day < value; day += 1) {
            const from = moment().utc().subtract(+day, unit).format();
            tasks.push(
              projectionExpression ?
                callRESTThrottled(
                  'POST',
                  '/config/serve',
                  {
                    action: 'getTableItems',
                    table: tableName || 'Site',
                    totalLimit: 1,
                    extraParams: {
                      'ScanIndexForward': false,
                      'ProjectionExpression': projectionExpressionAliases,
                      'ExpressionAttributeValues': {
                        ':v': sitesn,
                        ':sort': from,
                      },
                      'ExpressionAttributeNames': ExpressionAttributeNames,
                      'KeyConditionExpression': 'SN = :v AND TS < :sort',
                    },
                  }
                ) :
                callRESTThrottled(
                  'POST',
                  '/config/serve',
                  {
                    action: 'getTableItems',
                    table: tableName || 'Site',
                    totalLimit: 1,
                    extraParams: {
                      'ScanIndexForward': false,
                      'ExpressionAttributeValues': {
                        ':v': sitesn,
                        ':sort': from,
                      },
                      'KeyConditionExpression': 'SN = :v AND TS < :sort',
                    },
                  }
                )
            );
          }
          Promise.all(tasks).then(res => {
            const processed = res.map((d) => d.result[0]).filter((d) => !!d).reverse()
              .map((d) => {
                let payload = d.payload;
                if (!payload) {
                  payload = d;
                }
                payload.TS = payload.TS.split('T')[0];
                payload.DailyPVValue = JSON.parse(payload.DailyPV).slice(-1)[0];
                payload.DailyWindValue = JSON.parse(payload.DailyWind).slice(-1)[0];
                return { date: payload.TS, value: payload };
              });
            resolve(processed);
          });
        } else {

          const from = moment().utc().subtract(+value, unit).format();
          if (projectionExpression) {
            callRESTThrottled(
              'POST',
              '/config/serve',
              {
                action: 'getTableItems',
                table: tableName || 'Site',
                totalLimit: 2000,
                extraParams: {
                  'ScanIndexForward': false,
                  'ProjectionExpression': projectionExpressionAliases,
                  'ExpressionAttributeValues': {
                    ':v': sitesn,
                    ':sort': from,
                  },
                  'ExpressionAttributeNames': ExpressionAttributeNames,
                  'KeyConditionExpression': 'SN = :v AND TS >= :sort',
                },
              }
            ).then((d) => {
              const processed = d.result.reverse().map((d) => {
                let payload = d.payload;
                if (!payload) {
                  payload = d;
                }
                return { date: d.TS, value: payload };
              });
              if (propsField) {
                dispatch(dispatcher('UPDATE_SITE_MODULE', { [propsField]: processed }));
              }
              resolve(processed);
            });
          }
          else {
            callRESTThrottled(
              'POST',
              '/config/serve',
              {
                action: 'getTableItems',
                table: tableName || 'Site',
                totalLimit: 2000,
                extraParams: {
                  'ScanIndexForward': false,
                  'ExpressionAttributeValues': {
                    ':v': sitesn,
                    ':sort': from,
                  },
                  'KeyConditionExpression': 'SN = :v AND TS >= :sort',
                },
              }
            ).then((d) => {
              const processed = d.result.reverse().map((d) => {
                let payload = d.payload;
                if (!payload) {
                  payload = d;
                }
                return { date: d.TS, value: payload };
              });
              resolve(processed);
            });

          }

        }
      });
    };
  },

  loadFlatSiteData(sitesn, duration, tableName) {
    return function () {
      return new Promise((resolve) => {
        const value = duration.substring(0, duration.length - 1);
        const curUnit = duration.substring(duration.length - 1, duration.length);
        const unit = {
          M: 'minutes',
          H: 'hours',
          D: 'days',
        }[curUnit];
        const from = moment().utc().subtract(+value, unit).format();
        callRESTThrottled(
          'POST',
          '/config/serve',
          {
            action: 'getTableItems',
            table: tableName || 'Site',
            totalLimit: 2000,
            extraParams: {
              Flat: 'kW',
              ScanIndexForward: false,
              ExpressionAttributeValues: {
                ':v': sitesn,
                ':sort': from,
              },
              KeyConditionExpression: 'SN = :v AND TS >= :sort',
            },
          }
        ).then((d) => {
          resolve(d.result.reverse());
        });
      });
    };
  },
  loadAwardsDates(SN) {
    return async (dispatch) => {
      const { result } = await callRESTThrottled('POST', '/config/serve', {
        action: 'getTableItems',
        table: 'AWARD',
        extraParams: {
          'ScanIndexForward': false,
          'ExpressionAttributeValues': {
            ':sn': SN,
          },
          'KeyConditionExpression': 'SN = :sn',
        },
      });
      if (result.length) {
        dispatch(dispatcher('UPDATE_SITE_MODULE', { awardsDates: result }));
      }
    };
  },
  getAwardByDate(SN, date) {
    return (_) => new Promise((resolve) => {
      callRESTThrottled('POST', '/config/serve', {
        action: 'getTableItems',
        table: 'AWARD',
        extraParams: {
          'ScanIndexForward': false,
          'ExpressionAttributeValues': {
            ':sn': SN.concat('_', date),
          },
          'KeyConditionExpression': 'SN = :sn',
        },
      }).then((r) => {
        resolve(r);
      });
    });
  },
  uploadAwardData(SN, items) {
    return (_) => new Promise((resolve) => {
      callRESTThrottled('POST', '/config/serve', {
        action: 'saveAwards',
        SN,
        items,
      }).then((r) => {
        resolve(r);
      });
    });
  },
  uploadIndex(SN, item) {
    return (_) => new Promise((resolve) => {
      callRESTThrottled('POST', '/config/serve', {
        action: 'saveIndex',
        SN,
        item,
      }).then((r) => {
        resolve(r);
      });
    });
  },
  uploadSocBalancing(SN, item) {
    return (_) => new Promise((resolve) => {
      callRESTThrottled('POST', '/config/serve', {
        action: 'saveSocBalancing',
        SN,
        item,
      }).then((r) => {
        resolve(r);
      });
    });
  },
  getEnergyProduction(type) {
    return (_) => new Promise((resolve) => {
      if (store.getState().site.siteMeta?.SN) {
        callRESTThrottled('POST', '/config/v2/serve', {
          action: 'getEnergyProduction',
          SN: store.getState().site.siteMeta.SN,
          type,
        }).then((r) => {
          resolve(r);
        });
      }
    });
  },
  getSLDTemplate() {
    return (_) => new Promise((resolve) => {
      callRESTThrottled('POST', '/config/v2/serve', {
        action: 'getSLDTemplate',
        sn: store.getState().site.siteMeta.SN,
      }).then((r) => {
        resolve(r);
      });
    });
  },
};