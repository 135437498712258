import React from 'react';
import { Component } from 'react';
import { storeConnector } from 'store';
import Input from './elements/Input/Input';
import { optionsSelectTZ } from '../utils';

class SettingsPopup extends Component {
  constructor(props) {
    super(props);
    this.changeTheme = this.changeTheme.bind(this);
    this.state = {
      preferredTzMode: this.props.preferredTzMode,
      showMap: this.props.showMap,
    };
  }

  async changeTheme() {
    await this.props.actions.switchTheme(!this.props.darkTheme);
    if (this.props.darkTheme){
      document.querySelector('body').classList.remove('theme--light');
      document.querySelector('body').classList.add('theme--dark');
    } else {
      document.querySelector('body').classList.remove('theme--dark');
      document.querySelector('body').classList.add('theme--light');
    }

    localStorage.setItem('lightTheme', !this.props.darkTheme);
  }
  async updateDefaultMapView() {
    this.setState({loading: true});
    await this.props.actions.changeUserData([{
      Name: 'custom:show_map',
      Value: (!this.props.showMap).toString(),
    }]);
    this.setState({loading: false}, () => {
      this.props.actions.updateStore({ user: {showMap: !this.props.showMap}});
    });
  }

  async updatePreferedTZ() {
    this.setState({loading: true});
    await this.props.actions.changeUserData([{
      Name: 'custom:preferred_timezone',
      Value: this.state.preferredTzMode,
    }]);
    this.setState({loading: false}, () => {
      this.props.actions.updateStore({ user: {preferredTzMode: this.state.preferredTzMode}});
    });
  }

  render() {
    return (
      <div className='cover-conteiner'>
        <div className='control-popup-container'>
          <div className='title-profile'>Settings</div>
          <div className='popup-close' onClick={()=>this.props.close()} />
          <div className='profile-content' style={{minWidth:'450px', alignItems: 'center'}}>
            <div>Theme dark mode</div>
            <Input
              onChange={() => this.changeTheme()}
              checked={this.props.darkTheme}
              type='toggle'
              label='changeTheme'
            />
            <div>Show map view by default</div>
            <div style={{zIndex: '1', display: 'flex', alignItems: 'center', opacity: '1'}}>
              <Input
                onChange={() => this.setState(({showMap}) => ({showMap: !showMap}))}
                checked={this.state.showMap}
                label='hideMapForUser'
                type='toggle'
                styleDD={{
                  maxWidth: '60px',
                  minWidth: '60px',
                  marginRight: 'auto',
                }}
              />
              {
                this.state.showMap !== this.props.showMap ?
                  <>
                    <button
                      style={{width: '56px'}}
                      className='type-small-light'
                      onClick={() => this.updateDefaultMapView()}
                    >
                    Save
                    </button>
                    <button
                      style={{width: '56px'}}
                      className='type-small-light'
                      onClick={() => this.setState({showMap: this.props.showMap})}
                    >
                    Cancel
                    </button>
                  </>
                  :
                  <></>
              }
            </div>
            <div>Preferred Timezone</div>
            <div style={{zIndex: '1', display: 'flex', alignItems: 'flex-start', opacity: '1'}}>
              <Input
                type='dropdown'
                placeholder='Select Device'
                ddMaxHeight='260'
                value={{value: this.state.preferredTzMode}}
                styleDD={{
                  maxWidth: '60px',
                  minWidth: '60px',
                  marginRight: 'auto',
                }}
                onChange={(val) => this.setState({preferredTzMode: optionsSelectTZ[val]})}
                options={ optionsSelectTZ }
              />
              {
                this.state.preferredTzMode !== this.props.preferredTzMode ?
                  <>
                    <button
                      style={{width: '56px'}}
                      className='type-small-light'
                      onClick={() =>this.updatePreferedTZ()}
                    >
                    Save
                    </button>
                    <button
                      style={{width: '56px'}}
                      className='type-small-light'
                      onClick={() => this.setState({preferredTzMode: this.props.preferredTzMode})}
                    >
                    Cancel
                    </button>
                  </>
                  :
                  <></>
              }
            </div>
          </div>
          {
            this.state.loading &&
            <div className='chat-spinner-holder center'>
              <div className='lds-ripple'><div /><div /></div>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default storeConnector(SettingsPopup, {
  service: ['darkTheme'],
  user: ['preferredTzMode', 'showMap']
});