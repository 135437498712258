import { dispatcher } from '../';
import { callRESTThrottled } from '../../utils';

export const units = {

  setUnit(unit) {
    return function (dispatch) {
      const currentUnit = unit || null;
      dispatch(dispatcher('UPDATE_DEVICES_MODULE', { currentUnit }));
    };
  },
  setLOTO(data){
    return function (dispatch) {
      return new Promise((resolve) => {
        callRESTThrottled('POST', '/config/v2/serve', {
          action: 'setLOTO',
          ...data,
        }).then(() => resolve());
      });
    };
  }
};