const initState = {
  VPPsSites: {},
  VPPsSitesMeta: {},
  VPPs: [],
  VPPsSitesSNs: [],
  VPPsSitesSelected: [],
  VPPSiteData: {},
};

export default (state = initState, action) => {
  const handlers = {
    'SELECT_VPP_SITE': () => {
      return {...state, VPPsSitesSelected: [...state.VPPsSitesSelected, action.sn]};
    },
    'DESELECT_VPP_SITE': () => {
      let arr = [...state.VPPsSitesSelected];
      if(!action.sn){
        arr = [];
      }
      arr.remove(action.sn);
      return {...state, VPPsSitesSelected: arr};
    },
    'UPDATE_VPP_MODULE': () => {
      delete action.type;
      return {...state, ...action};
    },
  };
  if (!handlers[action.type])
    return state;
  return handlers[action.type]();
};