import moment from 'moment';
import React, { Suspense, Component } from 'react';
import { Router, Route, Switch, Link } from 'react-router-dom';

import slsConfig from 'sls-stack-output.json';

import { storeConnector } from './store';

import { ReactComponent as CautionIcon } from './assets/caution.svg';
import { ReactComponent as WarningIcon } from './assets/warning.svg';
import { ReactComponent as FireIcon } from './assets/fire.svg';
import { ReactComponent as LoginIcon } from './assets/login.svg';

import PageNotFound from './components/elements/PageNotFound/PageNotFound';
import UserPanel from 'components/UserPanel/UserPanel';
import Notifications from './components/elements/Notifications/Notifications';
import SiteUnitComandsPopup from './components/Battery/OperationsAndMonitoring/SiteUnitComandsPopup/SiteUnitComandsPopup';
import ChangePasswordPopup from './components/ChangePasswordPopup';
import SupportPopup from './components/SupportPopup';
import SettingsPopup from './components/SettingsPopup';
import Clock from './components/elements/clock/Clock';
import Input from 'components/elements/Input/Input';

import './app.css';

const { FontAwesomeIcon } = React.lazy(() => import('@fortawesome/react-fontawesome'));

const Editor = React.lazy(() => import('./components/Editor'));
const Login = React.lazy(() => import('./components/Login/Login'));
const History = React.lazy(() => import('./components/Home/History/History'));

const PasswordReset = React.lazy(() => import('./components/PasswordReset'));
const MFASetup = React.lazy(() => import('./components/MFASetup/MFASetup'));
const TotpRequired = React.lazy(() => import('./components/TotpRequired'));
const VppSelect = React.lazy(() => import('./components/Vpp/VppSelect/VppSelect'));
const ResetForgottenPassword = React.lazy(() => import('./components/ResetForgottenPassword'));
const Home = React.lazy(() => import('./components/Home/Home/Home'));

const Wind = React.lazy(() => import('./components/Wind/Wind'));
const Sun = React.lazy(() => import('./components/Sun/Sun'));
const MKT = React.lazy(() => import('./components/Mkt/Mkt'));

const Battery = React.lazy(() => import('./components/Battery/Battery'));
const DataTableView = React.lazy(() => import('./components/Battery/Historian/DataTableView/DataTableView'));
const Admin = React.lazy(() => import('./components/Admin/index'));

const CHECK_AUTOLOGOUT_INTERVAL = 1000*3;// ms

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserPanel: false,
      supportPopup: false,
      fastStopPopup: false,
      lastActivity: null,
      changePasswdPopup: false,
      settingsPopup: false,
    };
  }

  async componentDidMount() {
    document.body.removeAttribute('loading');
    document.getElementById('ems-preload-spinner-container').remove();
    this.setState({lastActivity: (new Date()).getTime()});
    this.props.actions.switchTheme(!(localStorage.getItem('lightTheme') === 'true'));
    localStorage.setItem('lightTheme', localStorage.getItem('lightTheme') === 'true');

    window.onresize = () => {
      window.scrollTo(0, 0);
    };
    document.querySelector('body').classList
      .add(`theme--${localStorage.getItem('lightTheme') === 'true' ? 'light' : 'dark'}`);
    await this.props.actions.initUser();
    document.onmousemove = e => this.activityHandler(e);
    document.ontouchmove = e => this.activityHandler(e);
    document.onclick = e => this.activityHandler(e);
    var pjson = require('../package.json');
    this.setState({
      version: pjson.version,
    });
    const path = this.props.history.location.pathname;
    const htmlElem = document.getElementsByTagName( 'html' )[0];
    htmlElem.setAttribute('class', this.htmlClassDuePath(path));
    this.unlisten = this.props.history.listen(({ pathname, a, b }) => {
      const htmlElem = document.getElementsByTagName( 'html' )[0];
      htmlElem.setAttribute('class', this.htmlClassDuePath(pathname));
    });
    if (this.props.user.group) {
      this.props.actions.getVPP();
    }
    if (this.props.siteMeta?.SiteName){
      document.title = `Fractal EMS - ${this.props.siteMeta.SiteName}`
    }
  }
  htmlClassDuePath(path) {
    const pathArr = path.split('/');
    const firstLevelPath = ['battery'];
    const thirdLevelPath = ['alarm_and_warning','historian', 'calendar'];
    const fourthLevelPath = ['meter_and_status'];
    const onlyFirstLevelPath = ['historian-tableview'];
    const pathForHeightAutoOnSmallScreen = [''];
    if (
      [...firstLevelPath, ...onlyFirstLevelPath, ...pathForHeightAutoOnSmallScreen]
        .includes(pathArr[1])
    ) {
      if (
        thirdLevelPath.includes(pathArr[3]) ||
        onlyFirstLevelPath.includes(pathArr[1]) ||
        fourthLevelPath.includes(pathArr[4])
      ) {
        return 'auto-height';
      }
      if (pathForHeightAutoOnSmallScreen.includes(pathArr[1])) {
        return 'auto-height-small-screen';
      }
      return 'media-large';
    }
    return '';
  }

  componentWillUnmount(path) {
    this.unlisten();
  }

  alarmState() {
    if (this.props.alarms) {
      const arr = Object.values(this.props.alarms).reduce((acc, cv) => {
        return [...acc, ...cv.Alarms];
      }, []);
      const alarmWarn = arr.reduce(
        (acc, cv) => {
          if (cv['Active?']) {
            // if (cv.Severity >= 1) {
            if (cv.Severity === 1) {
              return [acc[0] + 1, acc[1], acc[2]];
            } else if (cv.Severity === 0) {
              return [acc[0], acc[1] + 1, acc[2]];
            } else if (cv.Severity === 2) {
              return [acc[0], acc[1], acc[2] + 1];
            }
          }
          return acc;
        },
        [0, 0, 0]
      );
      return alarmWarn;
    } else {
      return [0, 0, 0];
    }
  }

  activityHandler(event){
    if (window.timerActivityHandler) {
      clearTimeout(window.timerActivityHandler);
    }
    if(this.props.user.username){
      window.timerActivityHandler = window.setTimeout(() => {
        const currDate = moment().unix();
        localStorage.setItem('INACTIVITY_LOGOUT_DATE_PREV', currDate);
      }, CHECK_AUTOLOGOUT_INTERVAL);
    }
  }

  setDefaultLogo() {
    const logo = document.querySelector('#project-logo');
    logo.src = require('./assets/logo.png');
  }
  async sendFastStop() {
    await this.props.actions.sendCommand(['WriteToVariant::FastStopSiteUI::1'],
      this.props.currentSite.SN);
    this.props.actions.notify('Command was sent');
    this.setState({fastStopPopup: false});

  }

  componentDidUpdate(prevProps) {
    if (this.props.siteMeta && prevProps.siteMeta?.SiteName !== this.props.siteMeta.SiteName){
      document.title = `Fractal EMS - ${this.props.siteMeta.SiteName}`
    }
    if (!this.props.siteMeta || !this.props.siteMeta.SiteName) {
      document.title = 'Fractal EMS';
    }
  }

  handleChangeSiteDropdown(val) {
    const pathArr = this.props.currentPath.split('/');
    const siteSN = Object.keys(this.props.VPPsSitesMeta)[val];
    pathArr.splice(2, 1, siteSN);
    this.props.actions.clearSiteMeta();
    this.props.actions.getSiteMeta(siteSN);
    this.props.actions.getVPP();
    if (
      (pathArr[1] === 'sun' && !+this.props.VPPsSitesMeta[siteSN].NumPV) ||
      (pathArr[1] === 'wind' && !+this.props.VPPsSitesMeta[siteSN].NumWind) ||
      (pathArr[1] === 'mkt' && [0, '0'].includes(this.props.VPPsSitesMeta[siteSN].Mkt_Enable))
    ) {
      pathArr.splice(1, 1, 'home');
      pathArr.length = 3;
    }
    this.props.history.push(pathArr.join('/'));
  }

  render () {
    return (
      <Router history={this.props.history}>
        <Suspense fallback={
          <div className='spinner-holder'>
            <div className='lds-ripple'><div /><div /></div>
          </div>
        }>
          <div className={'page'} style={{scrollbarWidth: '50px'}}>
            <Notifications />
            <SiteUnitComandsPopup />
            <UserPanel showUserPanel={this.state.showUserPanel}
              close={() => this.setState({showUserPanel: false})}
              openFastStopPopup={() => this.setState({fastStopPopup: true})}
              openChangePasswdPopup={() => this.setState({ changePasswdPopup: true })}
              openSettingsPopup={() => this.setState({ settingsPopup: true })}
              openSupportPopup={() => this.setState({supportPopup: true})}
            />
            { this.state.changePasswdPopup &&
            <ChangePasswordPopup close={()=>this.setState({changePasswdPopup: false})} />
            }
            { this.state.settingsPopup &&
            <SettingsPopup close={()=>this.setState({settingsPopup: false})} />
            }
            {
              this.state.supportPopup &&
            <SupportPopup close={()=>this.setState({supportPopup: false})} />
            }
            {
              this.state.fastStopPopup &&
            <div className='cover-conteiner'>
              <div className='control-popup-container'>
                <div className='title-profile' style={{paddingRight: '30px'}}>
                  Are you sure you want to execute?
                </div>
                <div className='content-popup'>
                  <div style={{margin: '20px'}}>Fast Stop</div>
                  <div className='btn-row'>
                    <button
                      onClick={() => this.sendFastStop()}
                      style={{width:'100px'}}
                    >
                      OK
                    </button>
                    <button
                      onClick={() => this.setState({fastStopPopup: false})}
                      style={{width:'100px'}}
                    >
                      CANCEL
                    </button>
                  </div>
                </div>
              </div>
            </div>
            }

            {
              (
                (this.props.loading && !this.props.loaders.login) ||
                (this.props.mfaConfigured === null && this.props.user.username)
              ) &&
              <div className='spinner-holder'>
                <div className='lds-ripple'><div /><div /></div>
              </div>
            }
            {this.props.connectionAlert &&
                  <div className='alert-message red' onClick={()=> this.props.actions.initUser()}>
                    <div className='icon'>
                      <FontAwesomeIcon icon='exclamation-circle'/>
                    </div>
                    <div className='info'>
                      <div className='title'>Connection lost</div>
                      <div className='message'>trying to reconnect...</div>
                    </div>
                  </div>
            }
            {
              this.props.user.username &&
              <div className='bar'>
                <div className='version-label'>
                  v{this.state.version}
                </div>

                <Link to='/' className='link' onClick={() => this.props.actions.clearCurrentSite()}>
                  <img id='project-logo' src='/logos/customerLogo.png' alt=''
                    onError={() => this.setDefaultLogo()} />
                </Link>
                {
                  this.props.siteMeta ?
                    <>
                      <div className='sndesc'>
                        <Input
                          type='dropdown'
                          value={{ value: this.props.siteMeta.SiteName }}
                          styleDD={{ zIndex: '101', marginBottom: '4px'}}
                          onChange={(val) => this.handleChangeSiteDropdown(val)}
                          options={
                            Object.keys(this.props.VPPsSitesMeta)
                              .map(siteSn => this.props.VPPsSitesMeta[siteSn].SiteName)
                          }
                        />
                        <div className='sn'>{this.props.siteMeta.Descriptor}</div>
                        <div className='descr'>
                          {this.props.siteMeta.kWMaxP/1000} MW /
                          {this.props.siteMeta.kWHRtg/1000} MWh
                        </div>
                      </div>

                      <div className='items'>
                        <Link to={'/home/' + this.props.siteMeta.SN}
                          className={
                            'link ' + (this.props.currentPath.startsWith('/home')?'active':'')}
                        >
                          {
                            this.props.currentPath.startsWith('/home') ?
                              <img src={require('./assets/home.svg')} className='icon' alt=''
                                title='Home'
                              />
                              :
                              <img src={require('./assets/home_inactive.svg')}
                                className='icon inactive' alt='' title='Home'
                              />
                          }
                        </Link>
                        <Link
                          to={`/battery/${this.props.siteMeta.SN}/operations_and_monitoring/sys/`}
                          className={
                            'link ' + (this.props.currentPath.startsWith('/battery')?'active':'')}
                        >
                          {
                            this.props.currentPath.startsWith('/battery') ?
                              <img src={require('./assets/battery.svg')} className='icon' alt=''
                                title='Battery'
                              />
                              :
                              <img src={require('./assets/battery_inactive.svg')}
                                className='icon inactive' alt='' title='Battery'
                              />
                          }
                        </Link>
                        { +this.props.siteMeta.NumPV ?
                          <Link to={`/sun/${this.props.siteMeta.SN}/operations_and_monitoring`}
                            className={
                              'link ' + (this.props.currentPath.startsWith('/sun')?'active':'')}
                          >
                            {
                              this.props.currentPath.startsWith('/sun') ?
                                <img src={require('./assets/sun.svg')} className='icon' alt=''
                                  title='Sun'
                                />
                                :
                                <img src={require('./assets/sun_inactive.svg')}
                                  className='icon inactive' alt='' title='Sun'
                                />
                            }
                          </Link>
                          :
                          <></>
                        }
                        {
                          +this.props.siteMeta.NumWind ?
                            <Link to={'/wind/' + this.props.siteMeta.SN}
                              className={
                                'link ' + (this.props.currentPath.startsWith('/wind')?'active':'')}
                            >
                              {
                                this.props.currentPath.startsWith('/wind') ?
                                  <img src={require('./assets/wind-turbine.svg')} className='icon'
                                    alt='' title='Wind'
                                  />
                                  :
                                  <img src={require('./assets/wind-turbine_inactive.svg')}
                                    className='icon inactive' alt='' title='Wind'
                                  />
                              }
                            </Link>
                            :
                            <></>
                        }
                        <Link to={'/history/' + this.props.siteMeta.SN}
                          style={{display: 'flex', alignItems: 'center'}}
                          className={
                            'link ' + (this.props.currentPath.startsWith('/history')?'active':'')}
                        >
                          {
                            this.props.currentPath.startsWith('/history') ?
                              <img src={require('./assets/chart.svg')} className='icon' alt=''
                                title='History'
                              />
                              :
                              <img src={require('./assets/chart_inactive.svg')}
                                className='icon inactive' alt='' title='QuickSight'
                              />
                          }
                        </Link>
                        {![0,'0'].includes(this.props.siteMeta.Mkt_Enable) ?
                          <Link to={`/mkt/${this.props.siteMeta.SN}/operations_and_monitoring`}
                            className={
                              'link ' + (this.props.currentPath.startsWith('/mkt')?'active':'')}
                          >
                            {
                              this.props.currentPath.startsWith('/mkt') ?
                                <img src={require('./assets/dollar.svg')}
                                  className='icon dollar active-usd' alt='' title='MKT'
                                />
                                :
                                <img src={require('./assets/dollar-inactive.svg')}
                                  className='icon dollar inactive usd-icon' alt='' title='MKT'
                                />
                            }
                          </Link>
                          :
                          <></>
                        }

                      </div>
                    </>
                    :
                    <>
                      <div className='sndesc title'>
                        {(this.props.user.group === 'Administrator' &&
                          this.props.currentPath.startsWith('/admin')) ?
                          'Admin Panel'
                          :
                          'Fleet Manager ' + (this.props.VPPs.Description ? ('– '+this.props.VPPs.Description) : '')
                        }
                      </div>
                      <div className='items' />
                    </>

                }
                <div className='alerts'>
                  {this.props.siteMeta && this.props.currentSite && (
                    <>
                      {this.alarmState()[2] > 0 ? (
                        <Link
                          to={`/battery/${this.props.siteMeta.SN}/alarm_and_warning`}
                        >
                          <FireIcon className='icon active' />
                        </Link>
                      ) : (
                        <FireIcon className='icon' />
                      )}
                      {this.alarmState()[0] > 0 ? (
                        <Link
                          to={`/battery/${this.props.siteMeta.SN}/alarm_and_warning`}
                        >
                          <WarningIcon className='icon active' />
                        </Link>
                      ) : (
                        <WarningIcon className='icon' />
                      )}
                      {this.alarmState()[1] > 0 ? (
                        <Link
                          to={`/battery/${this.props.siteMeta.SN}/alarm_and_warning`}
                        >
                          <CautionIcon className='icon active' />
                        </Link>
                      ) : (
                        <CautionIcon className='icon' />
                      )}
                    </>
                  )}
                </div>

                {
                  this.props.currentSite &&
                  <Clock/>
                }
                <div style={{cursor: 'pointer', marginLeft: '12px'}}
                  onClick={() => this.setState({showUserPanel: true})}
                >
                  <LoginIcon className='icon login' />
                </div>

              </div>
            }
            {
              this.props.userLoaded && (
                <Switch className='content'>
                  <Route exact path='/login' render={() => <Login />} />
                  <Route exact path='/forgotPassword' render={() => <ResetForgottenPassword />} />
                  <Route exact path='/passwordReset' render={() => <PasswordReset />} />
                  <Route exact path='/totpRequired' render={() => <TotpRequired />} />
                  {
                    this.props.user.username && this.props.mfaConfigured !== null &&
                    [
                      ...(
                        (this.props.mfaConfigured !== false || slsConfig.MFA === 'OFF') ?
                          [
                            <Route key='/' exact path='/' render={() => <VppSelect />} />,
                            <Route key='/home/:sitesn' exact path='/home/:sitesn'
                              render={(p) => <Home key={p.match.params.sitesn} match={p.match} />}
                            />,
                            <Route key='/battery/:sitesn/*' exact path='/battery/:sitesn/*'
                              render={(p) => <Battery key={p.match.params.sitesn} match={p.match}/>}
                            />,
                            <Route key='/sun/:sitesn/*' exact path='/sun/:sitesn/*'
                              render={(p) => <Sun key={p.match.params.sitesn} match={p.match} />}
                            />,
                            <Route key='/mkt/:sitesn/*' exact path='/mkt/:sitesn/*'
                              render={(p) => <MKT key={p.match.params.sitesn} match={p.match} />}
                            />,
                            <Route key='/wind/:sitesn' exact path='/wind/:sitesn'
                              render={(p) => <Wind key={p.match.params.sitesn} match={p.match} />}
                            />,
                            <Route key='/history/:sitesn' exact path='/history/:sitesn'
                              render={(p) => <History key={p.match.params.sitesn} match={p.match}/>}
                            />,
                            <Route key='/config/' exact path='/config/' render={()=> <Editor />} />,
                            <Route key='/config/:table' exact path='/config/:table'
                              render={(p) => <Editor match={p.match} />}
                            />,
                            <Route key='/historian-tableview/:request' exact
                              path='/historian-tableview/:request'
                              render={(p) => <DataTableView match={p.match} />}
                            />,
                            ...(
                              this.props.user.group === 'Administrator' ?
                                [<Route key='/admin' path='/admin' render={() => <Admin />}/>]
                                : []
                            ),
                          ] :
                          [<Route key='/' path='/' render={() => <MFASetup />} />]
                      )
                    ]
                  }
                  <Route render={() => <PageNotFound />}/>
                </Switch>
              )

            }
            <div className='powered-by'>
              <div className='powered'>Powered By:</div>
              <img src={require('./assets/logo.png')} alt=''/>
              <div className='company-name'>FRACTAL EMS</div>
            </div>
          </div>
        </Suspense>
      </Router>
    );
  }
}

export default storeConnector(App, {
  service: ['history', 'darkTheme', 'loading', 'loaders', 'currentPath'],
  user: ['user', 'mfaConfigured', 'userLoaded'],
  vpp: ['VPPs', 'VPPsSitesMeta'],
  site: 'all',//['currentSite', 'siteMeta'],
  messages: ['alarms', 'connectionAlert'],
});