import { combineReducers } from 'redux';
import service from './serviceReducer';
import user from './userReducer';
import vpp from './vppReducer';
import devices from './devicesReducer';
import site from './siteReducer';
import messages from './messagesReducer';
import calendar from './calendarReducer';

export default combineReducers({
  service,
  user,
  vpp,
  devices,
  site,
  messages,
  calendar
});