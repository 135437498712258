import sigV4Client from './sigV4Client';

// import AWS from 'aws-sdk';
import slsConfig from './sls-stack-output.json';
//import moment from 'moment-timezone';
import { store } from 'store';
import { notifyError } from 'store/actionCreators/notifications';
import { authorization } from 'store/actionCreators/authorization';
import tzlookup from 'tz-lookup';


var calendarUtils = require('./calendarUtils');

export const optionsSelectTZ = ['UTC', 'Site', 'User'];
export const dummySite = {
  'DailyPV': '[0,0,0,0,0,0,0]',
  'DailyWind': '[0,0,0,0,0,0,0]',
  'HourlyPV': '[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]',
  'HourlyWind': '[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]',

  'AcAlarm': '-',
  'AllowedMaxP': '-',
  'AllowedMaxQ': '-',
  'AllowedMinP': '-',
  'AllowedMinQ': '-',
  'AmbHighTemp': '-',
  'AmbHighTempAlarm': '-',
  'AmbLowTemp': '-',
  'AmbLowTempAlarm': '-',
  'AphA': '-',
  'AphB': '-',
  'AphC': '-',
  'Application': '-',
  'AvailEnergy': '-',
  'AvailP': '-',
  'AvgHumidity': '-',
  'AvgSOH': '-',
  'BatteryFault': '-',
  'BatteryStringHighTemp': '-',
  'BatteryStringLowTemp': '-',
  'BatteryWarning': '-',
  'ChaSt': '-',
  'CommError': '-',
  'ContactorError': '-',
  'Current': '-',
  'CurrImbWarning': '-',
  'Description': '-',
  'DisconnectOpen': '-',
  'DisconnectTrip': '-',
  'DoorOpen': '-',
  'ESTOP': '-',
  'FanError': '-',
  'FaultEvent': '-',
  'FireAlarm': '-',
  'FireSupervisory': '-',
  'FireTrouble': '-',
  'Grid Warning': '-',
  'GridFault': '-',
  'GroundFault': '-',
  'HighCurrentAlarm': '-',
  'HighHumidity': '-',
  'HighSOCFault': '-',
  'HighSOCWarning': '-',
  'HighVoltAlarm': '-',
  'Hz': '-',
  'IsolationFault': '-',
  'kVAr': '-',
  'kW': '-',
  'LMP': '-',
  'Load': '-',
  'LocRemCtl': '-',
  'LowSOCFault': '-',
  'LowSOCWarning': '-',
  'LowVoltAlarm': '-',
  'MaxAmbT': '-',
  'MaxCellT': '-',
  'MaxSOC': '-',
  'MinAmbT': '-',
  'MinCellT': '-',
  'MinSOC': '-',
  'NotEnoughStrings': '-',
  'NumPVOnline': '-',
  'NumWindOnline': '-',
  'Other': '-',
  'OtherFault': '-',
  'OVWarning': '-',
  'PCSFault': '-',
  'PCSWarning': '-',
  'PF': '-',
  'PhVphA': '-',
  'PhVphB': '-',
  'PhVphC': '-',
  'PPVphAB': '-',
  'PPVphAC': '-',
  'PPVphBC': '-',
  'Pset': '-',
  'PVkW': '-',
  'Qset': '-',
  'SiteBreaker': '-',
  'SN': '-',
  'SoC': '-',
  'TempImbFault': '-',
  'TempImbWarning': '-',
  'UnitsOnline': '-',
  'UVWarning': '-',
  'Version': '-',
  'Voltage': '-',
  'VoltImbWarning': '-',
  'WarningEvent': '-',
  'WindkW': '-'
};

export function log(...args) {
  /* eslint-disable-next-line */
  // console.log(moment().format('Do, hh:mm:ss.SSS'), ...args)
}

export function callRESTThrottled(method, url, data = {}, auth = true, cache = false, hasCache, getCache, setCache) {
  return new Promise(async (resolve, reject) => {
    window.getState = store.getState;

    let creds = store.getState().user.awsCredentials;
    const user = store.getState().user.cognitoUser;

    if (!(user?.signInUserSession?.isValid() ?? true) || creds.expired) {
      const refreshPromise = new Promise((resolve, reject) => {
        user.refreshSession(user.signInUserSession.refreshToken, (err, session) => {
          if (err) reject(err);
          else resolve(session);
        });
      });
      const session = await refreshPromise;
      const client = store.getState().user.client;
      if (client) {
        client.noDelayed = true;
      }
      await store.dispatch(authorization.getCredentials(session, true));
      creds = store.getState().user.awsCredentials;
    }

    const client = auth ? sigV4Client.newClient({
      accessKey: creds.accessKeyId,
      secretKey: creds.secretAccessKey,
      sessionToken: creds.sessionToken,
      region: slsConfig.Region,
      endpoint: slsConfig.ServiceEndpoint,
    }) : undefined;
    if (client === undefined && auth) {
      log('Unable to create a sigV4Client see error above');
      reject();
    }

    const signedBody = data ? JSON.stringify(data) : data;
    let urlFetch;
    const fetchParams = {
      method,
      body: signedBody,
    };
    if (auth) {
      const signedRequest = client.signRequest({
        method,
        path: url,
        headers: {},
        queryParams: {},
        body: data,
      });
      fetchParams.headers = signedRequest.headers;
      urlFetch = signedRequest.url;
    } else {
      urlFetch = `${slsConfig.ServiceEndpoint}/${url}`;
    }

    let cacheExists = false;
    let results = null;
    // console.log('cache Exists 1', cache, data, data.category);
    if (cache && data.action === "getOptions") {
      results = await getCache({ ...data })
      if (cache && results) {
        cacheExists = true;
        resolve(results);
      }
      else console.log('cache result does not exist')
    }

    if (!cacheExists) {
      fetch(urlFetch, fetchParams).then((result) => {
        if (result.status === 403) {
          store.dispatch(notifyError(urlFetch, 'Session expired, please relogin'));
          store.dispatch(authorization.logout());
          return;
        }

        if (result.status !== 200) {
          result.json().then((resp) => {
            store.dispatch(
              notifyError(`Backend error ${JSON.stringify(resp)}, please contact support`)
            );
            reject();
            return;
          }).catch(() => {
            result.text().then((resp) => {
              store.dispatch(
                notifyError(`Backend error ${JSON.stringify(resp)}, please contact support`)
              );
              reject();
              return;
            }).catch(() => {
              store.dispatch(notifyError('Backend unknown error, please contact support'));
              reject();
              return;
            });
          });
        } else { //otherwise we need to cache this result before we resolve it - if caching is turned on here
          result.json().then((d) => {
            if (cache) setCache({ ...data }, d);
            resolve(d);
          });
        }
      }).catch((error) => {
        if (error?.code === 20) {
          console.log('request aborted');
        } else {
          console.error('caught error', error);
          store.dispatch(notifyError(`Backend communication error ${error}, please contact support`));
        }
      });
    }
  }
  );

}

let pendingRequests = [];
let activeRequest = false;

function initCaller() {
  if (activeRequest) {
    return;
  }
  if (pendingRequests.length) {
    activeRequest = true;
    const req = pendingRequests.shift();
    // log('Executing REST', req);

    callRESTThrottled(req.method, req.url, req.data, req.auth).then((data) => {
      activeRequest = false;
      initCaller();
      req.resolve(data);
    }).catch((error) => {
      log('Error calling rest', error);
      activeRequest = false;
      initCaller();
      req.reject(error);
    });
  }
}

export function cancelPendingRequests() {
  pendingRequests = pendingRequests.filter((d) => {
    return !d.data || !(d.data.action === 'getTableItems' && d.data.table === 'MSG');
  });
}

export function callREST(method, url, data = {}, auth = true) {
  const promise = new Promise((resolve, reject) => {
    pendingRequests.push({ method, url, data, auth, resolve, reject });
    initCaller();
  });
  return promise;
}

export function tsDueTimeMode(data) {
  const moment = require('moment-timezone');
  const { ts, timeMode, lat, long, format, splited, returnMoment, prevTimeMode } = data;
  const modesTz = {
    UTC: 'UTC',
    Site: tzlookup(lat, long),
    User: moment.tz.guess(),
  };
  const tz = modesTz[timeMode];
  if (prevTimeMode) {
    const momentDate = moment.tz(ts || moment(), modesTz[prevTimeMode]).tz(tz);
    if (returnMoment) {
      return momentDate;
    }
    const date = momentDate.format(format || 'YYYY-MM-DD HH:mm:ss');
    return splited ? [date.split(' ')[0], date.split(' ')[1]] : date;
  }
  const momentDate = moment.tz(ts || moment(), tz);
  if (returnMoment) {
    return momentDate;
  }
  const date = momentDate.format(format || 'YYYY-MM-DD HH:mm:ss');
  return splited ? [date.split(' ')[0], date.split(' ')[1]] : date;
}

export function getLocale(data) {
  const moment = require('moment-timezone');
  const { timeMode, lat, long, returnOffset, initTZMode } = data;
  const modesTz = {
    UTC: 'UTC',
    Site: tzlookup(lat, long),
    User: moment.tz.guess(),
  };
  if (returnOffset) {
    if (initTZMode) {
      return -moment().tz(modesTz[timeMode]).utcOffset() + moment().tz(modesTz['User']).utcOffset();
    }
    return -moment().tz(modesTz[timeMode]).utcOffset() + moment().tz(modesTz['User']).utcOffset();
  }
  return modesTz[timeMode];
}

export function currentTzString(data) {
  const moment = require('moment-timezone');
  const { timeMode, lat, long } = data;
  const modesTz = {
    UTC: 'UTC±00:00',
    Site: 'UTC' + moment().tz(tzlookup(lat, long)).format('Z'),
    User: 'UTC' + moment().tz(moment.tz.guess()).format('Z'),
  };
  return (modesTz[timeMode]);

}

export function isEmptyObject(obj) {
  return !Object.keys(obj || {}).length;
}

export function dateToUTC(data) {
  const moment = require('moment-timezone');
  const { timeMode, lat, long, ts } = data;
  if (!ts) {
    return;
  }
  const modesTz = {
    UTC: 'UTC',
    Site: tzlookup(lat, long),
    User: moment.tz.guess(),
  };
  const currentTz = modesTz[timeMode];
  const currDate = moment.tz(ts.replace(' ', 'T'), currentTz);
  return currDate;
}

export function siteDateTimeToDateTimeMode(data) {
  const moment = require('moment-timezone');
  const { timeMode, lat, long, date, time, reverse } = data;
  const modesTz = {
    UTC: 'UTC',
    Site: tzlookup(lat, long),
    User: moment.tz.guess(),
  };
  const currentTz = modesTz[timeMode];
  const siteTz = modesTz.Site;
  const dateArr = date.split('/');
  const timeArr = time.split(':');
  if (reverse) {
    const siteOffset = moment().tz(tzlookup(lat, long)).utcOffset();
    const currDate = moment.tz(
      `${dateArr[2]}-${dateArr[0]}-${dateArr[1]}T${timeArr[0]}:${timeArr[1]}:${timeArr[2]}`,
      currentTz
    );
    const siteDate = currDate.clone().utcOffset(siteOffset);
    return siteDate;
  }
  const siteDate = moment.tz(
    `${dateArr[2]}-${dateArr[0]}-${dateArr[1]}T${timeArr[0]}:${timeArr[1]}:${timeArr[2]}`,
    siteTz
  );
  const currDate = siteDate.tz(currentTz);
  return currDate;
}

export function formatTS(ts, timeMode, format = 'YYYY-MM-DD HH:mm:ss') {
  const moment = require('moment-timezone');
  if (timeMode === 'utc') {
    return moment.utc(ts).format(format);
  } else {
    return moment(ts).local().format(format);
  }
}

export function formatTSforMessage(ts, timeMode, format = 'YYYY-MM-DD HH:mm:ss:SSS') {
  const moment = require('moment-timezone');
  if (timeMode === 'utc') {
    return {
      d: moment.utc(ts).format(format).split(' ')[0],
      t: moment.utc(ts).format(format).split(' ')[1] + 'Z',
    };
  } else {
    return {
      d: moment(ts).local().format(format).split(' ')[0],
      t: moment(ts).local().format(format).split(' ')[1] + 'Z',
    };
  }
}

export function fixHistorianDate(ts) {
  const moment = require('moment-timezone');
  return ts.clone().add(-moment().tz(moment.tz.guess()).utcOffset(), 'm');
}

export function fmtNum(n, round = 2) {
  return (+n).toLocaleString(undefined, { maximumFractionDigits: round });
}

export function windDir(num) {
  const val = Math.floor((num / 22.5) + .5);
  const arr = ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW'];
  return arr[(val % 16)];
}

export const siteCommands = [
  'Stop Units',
  'Start Units',
  'Units to Manual',
  'Units to Auto',
  'Open AC Breakers',
  'Close AC Breakers',
  'Fault Reset',
];

export function filterEventsByTimeFrame(baseEvents, timeFrame, tz) {
  return calendarUtils.filterEventsByTimeFrame(baseEvents, timeFrame, tz);
}

export function eventsToCalendarFormat(allEvents, tz, className) {
  const moment = require('moment-timezone');

  return allEvents.map((event) => {
    let title = '';
    title += event.startCommands.reduce((p, c) => c ? (`${p ? `${p}\n` : ''}↗${c}`) : p, '');
    if (event.startCommands.length) { title += '\n\n'; }
    title += event.stopCommands.reduce((p, c) => c ? (`${p ? `${p}\n` : ''}↘${c}`) : p, '');
    if (event.stopCommands.length) { title += '\n\n'; }
    if (event.stack) { title += `☰${event.stack}`; }
    return {
      id: event.ID,
      title: title,
      start: event.start,
      end: event.stop,
      className: `${className} eventEMS-${event.ID}`,
      backgroundColor: moment.tz(event.start, tz) < moment().tz(tz) ? 'gray' : event.color,
      rendering: 'event',
    };
  });
}

export function getRacksStatus() {
  const racksOnline = Object.values(store.getState().devices.batteryTable).reduce((acc, cv) => {
    if (cv.ConnectingStatus && cv.ConnectingStatus.length === 4) {
      const racksOnline = parseInt(cv.ConnectingStatus.substr(0, 2), 16);
      return acc + racksOnline;
    }
    return acc;
  }, 0);
  return [racksOnline, store.getState().site.siteMeta?.NumRacks || 0].join(' / ');
}

export function getTextColor(value) {
  const online = +value.split(' / ')[0];
  const all = +value.split(' / ')[1];
  if (online === 0) {
    return 'red-text';
  }
  if (online === all) {
    return 'green-text';
  }
  if (online < all) {
    return 'yellow-text';
  }
}

export const getPOIMeterPrefix = (index) => {
  const { MeterNames: meterNames } = store.getState().site.siteMeta;
  try {
    if (index === 0) return '';
    if (meterNames.length)
      return `${meterNames[index - 1].Name}_`;
    return '';
  } catch (error) {
    return '';
  }
};

export function getApparentPower(prefix) {
  const { currentSite, siteMeta } = store.getState().site;
  return (+siteMeta.MW_Enable) ? `${(Math.sqrt(Math.pow(currentSite[`${prefix}kW`], 2) +
    Math.pow(currentSite[`${prefix}kVAr`], 2)) / 1000).toFixed(1)} MVA` :
    `${(Math.sqrt(Math.pow(currentSite[`${prefix}kW`], 2) +
      Math.pow(currentSite[`${prefix}kVAr`], 2))).toFixed(0)} kVA`;
}

