import React, { Component } from 'react';
import './PageNotFound.scoped.scss';
import Windmill from '../Windmill/Windmill';

class PageNotFound extends Component {
  render() {
    return (
      <div className='page-not-found'>
        <Windmill
          opacity='0.8'
          top='100px'
          left='50px'
          scale='1.2'
        />
        <Windmill
          opacity='0.6'
          top='-20px'
          left='-40px'
          scale='0.6'
        />
        <div className='info'>
          <div className='first-line'> Oops! </div>
          <div className='second-line'>404 - Page not found</div>
        </div>
      </div>
    );
  }
}

export default PageNotFound;