import React from 'react';
import { Component } from 'react';
import './UserPanel.scoped.scss';
import { storeConnector } from '../../store';
import { ReactComponent as LoginIcon } from '../../assets/login.svg';

class UserPanel extends Component {
  render() {
    return (
      <div className={this.props.showUserPanel ? 'user-panel open' : 'user-panel'}>
        <div className='popup-close' onClick={() => this.props.close()} />
        {
          this.props.siteMeta && this.props.currentSite && this.props.siteMeta.FastStop_Enable &&
          <>
            <div>Send site command</div>
            <div className='switch'>
              <div className={'fast-stop-btn'} onClick={() => this.props.openFastStopPopup()}>
                Fast Stop
              </div>
            </div>
          </>
        }
        <LoginIcon className='icon login' />
        <div className='user-info'>
          <div className='bold'>{this.props.user.username}</div>
          <div>{this.props.user.email}</div>
          <div>{this.props.user.group}</div>
          <button className='logout-btn' onClick={() => this.props.actions.logout()}>Logout</button>
        </div>
        <div className={'links'}>
          <h6>Links</h6>
          <div onClick={() => this.props.openChangePasswdPopup()}>Change password</div>
          <div onClick={() => this.props.openSettingsPopup()}>Settings</div>
          {
            this.props.user.group === 'Administrator' ?
              <div onClick={() => {
                this.props.history.push(this.props.currentPath.startsWith('/admin') ? '' :'/admin');
              }}>
                {
                  this.props.currentPath.startsWith('/admin') ?
                    'Fleet Manager' : 'Admin Panel'
                }
              </div>
              : <></>
          }
          <div onClick={() => this.props.openSupportPopup()}>Support</div>
        </div>
      </div>
    );
  }
}

export default storeConnector(UserPanel, {
  service: ['currentPath', 'history'],
  user: ['user'],
  site: ['siteMeta', 'currentSite'],
});