import React, { Component } from 'react';
import './Input.scoped.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ddActive: false,
      showPassword: false,
      ddDirectionBottom: true,
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.timeHoursRef = React.createRef();
    this.timeMinutesRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    if (this.props.type === 'time') {
      const time = this.getTimeStrs();
      const refNames = ['timeHoursRef', 'timeMinutesRef'];
      this[refNames[0]].current.value = time[0];
      this[refNames[1]].current.value = time[1];
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (['dropdown', 'multiselect-dropdown', 'dropdown-multilevel'].includes(this.props.type)) {
        this.setState({ ddActive: false });
      }
    }
  }
  getMulttilevelValueText() {
    const opts = JSON.parse(JSON.stringify(this.props.options));
    return opts.map(opt => {
      if (opt.inputs && opt.isActive) {
        return opt.label + '(' + opt.inputs.map(inp => `${inp.label}:${inp.value}`).join(', ') + ')';
      } else if (opt.options && opt.options.length && opt.options.find(o => o.isActive)) {
        return opt.label + '(' + opt.options.filter(o => o.isActive).map(inp => inp.label)
          .join(', ') + ')';
      } else if (!opt.options && opt.isActive) {
        return opt.label;
      } else {
        return false;
      }
    }).filter(o => o !== false).join('\r\n');
  }
  setMulttilevelValue(action, params) {
    const opts = JSON.parse(JSON.stringify(this.props.options));
    if (action === 'showSubOpt') {
      opts[params.index].isActive = params.value;
    }
    if (action === 'checkSubOpt') {
      opts[params.index].options[params.indexSub].isActive =
        !opts[params.index].options[params.indexSub].isActive;
    }
    this.props.onChange(opts);
  }
  isMultiSelectOptionActive(opt) {
    if (opt.isActive && !opt.options) {
      return true;
    }
    if (opt.options && opt.options.find(o => o.isActive)) {
      return true;
    }
    return false;
  }
  selectUnselectAllSub(value, index) {
    const opts = JSON.parse(JSON.stringify(this.props.options));
    opts[index] = {
      ...opts[index],
      options: opts[index].options.map(o => ({ ...o, isActive: value }))
    };
    this.props.onChange(opts);
  }
  onChangeInpMultilevel(value, index, indexSub) {
    const opts = JSON.parse(JSON.stringify(this.props.options));
    opts[index] = {
      ...opts[index],
      inputs: opts[index].inputs
        .map((inp, i) => ({ ...inp, value: i === indexSub ? value : inp.value })),
    };
    this.props.onChange(opts);
  }
  setDDActive() {
    if (!this.state.ddActive) {
      const bodyHeight = document.body.clientHeight;
      const rect = document.getElementById('dd-inp-for-direction').getBoundingClientRect();
      const yBottom = rect.y + rect.height;
      const ddMaxHeight = this.props.ddMaxHeight || 106;
      if (ddMaxHeight + yBottom > bodyHeight) {
        this.setState({ ddActive: !this.state.ddActive, ddDirectionBottom: false });
      } else {
        this.setState({ ddActive: !this.state.ddActive, ddDirectionBottom: true });
      }
      return;
    }
    this.setState({ ddActive: !this.state.ddActive });
  }

  getTimeStrs() {
    if (this.props.type === 'time' && (this.props.value || this.props.value === 0)) {
      const hours = Math.floor(+this.props.value / 3600);
      const minutes = (this.props.value - hours * 3600) / 60;
      return [hours || '0', minutes || '00'];
    }
    return ['0', '00'];
  }
  formatValue(index, val) {
    if (index === 0) {
      return +val;
    }
    if (index === 1) {
      if (+val <= 59) {
        return +val;
      }
      if (+val / 100 >= 1) {
        const newVal = ('' + val).substring(0, 2);
        if (+newVal <= 59) {
          return +newVal;
        }
        return 59;
      }
    }
  }
  onChangetime(index, value) {
    const refNames = ['timeHoursRef', 'timeMinutesRef'];
    const hours = this.formatValue(0, this[refNames[0]].current.value);
    const minutes = this.formatValue(1, this[refNames[1]].current.value);
    const newTime = hours * 3600 + minutes * 60;
    this[refNames[index]].current.value = index === 0 ? hours : minutes;
    this.props.onChange(newTime);

  }

  render() {
    const { type, options, value, placeholder, objModeLabel, afterOptBtn, error,
      disabled, className, showPasswordIcon, noAutocomplete, disabledOptions, styleDD,
      rememberScrollPosition, label, onClick, key
    } = this.props;
    return (
      <>
        {type === 'smartsearchinput' &&
          // <div
          //   style={{ ...inputStyle }}
          //   className={`${className} ${disabled && 'disabled'} inp-container-gen dir-btm-${this.state.ddDirectionBottom} input-scrollbar`}
          //   ref={this.setWrapperRef}>
          //   {label && <div class='hint-label'>{label}</div>}
          <span id='dd-inp-for-direction'>
            <>
              <input key={key}
                onClick={onClick}
                className={`${disabled && 'disabled'} inp-container-gen dir-btm-${this.state.ddDirectionBottom} input-field ${this.state.ddActive ? 'act' : ''} ${className}`}
                ref={this.setWrapperRef}
                type="text"
                autoComplete={noAutocomplete ? 'new-password' : ''}
                value={value}
                disabled={disabled}
                placeholder={placeholder}
                onChange={(e) => this.props.onChange(e.target.value)}
              />
            </>
          </span>
        }
        {/* </div> */}

        {
          type === 'dropdown' &&
          <div
            style={styleDD || {}}
            className={`${className} ${disabled && 'disabled'} inp-container-gen dir-btm-${this.state.ddDirectionBottom} input-scrollbar`}
            ref={this.setWrapperRef}>
            {label && <div class='hint-label'>{label}</div>}
            <div className={`input-field ${this.state.ddActive ? 'act' : ''}`} onClick={() => this.setDDActive()} id='dd-inp-for-direction'>
              <>
                {
                  objModeLabel ?
                    <div>{value ? ((typeof value === 'string') ? value : value[objModeLabel]) : (placeholder || ' ')}</div>
                    :
                    <div className={`${[null, undefined, false].includes(value) && 'placeholder'}`}>
                      <span title={([null, undefined, false].includes(value) && placeholder && placeholder) || (value.value || (value.index !== undefined ? options[value.index] : ''))}>
                        {([null, undefined, false].includes(value) && placeholder && placeholder) || (value.value || (value.index !== undefined ? options[value.index] : ''))}
                      </span>
                    </div>
                }
              </>
              <div className={`cntrl-btn ${this.state.ddActive ? 'close-cntrl' : 'open'}`} />
            </div>
            {
              (options?.length && (this.state.ddActive || rememberScrollPosition)) ?
                <div className={`dd-options${this.state.ddActive ? '' : ' dd-hidden'}`}
                  style={this.props.ddMaxHeight ? { maxHeight: this.props.ddMaxHeight + 'px' } : {}}
                >
                  {
                    options.map((opt, i) =>
                      <div key={i} className={`dd-opt ${disabledOptions && disabledOptions.includes(opt) && 'disabled-opt'}`} onClick={() => {
                        if (disabledOptions && disabledOptions.includes(opt)) {
                          return;
                        }
                        this.props.onChange(objModeLabel ? opt : i);
                        this.setState({ ddActive: false });
                      }}
                        title={`${!objModeLabel && !(disabledOptions && disabledOptions.includes(opt)) ? opt : ''}`}

                      >
                        {objModeLabel ? (opt[objModeLabel] || opt) : opt}{
                          afterOptBtn && Object.keys(opt).includes(afterOptBtn.field) &&
                          <div onClick={(e) => { e.stopPropagation(); afterOptBtn.onClick(opt); }}
                            className={`after-btn ${opt[afterOptBtn.field] ?
                              afterOptBtn.activeIconClass : afterOptBtn.nonActiveIconClass} `
                            }
                          />}
                      </div>
                    )
                  }
                </div>
                :
                <></>
            }
          </div>
        }
        {
          type === 'multiselect-dropdown' &&
          <div
            style={styleDD || {}}
            className={`${className} ${disabled && 'disabled'} inp-container-gen dir-btm-${this.state.ddDirectionBottom}`}
            ref={this.setWrapperRef}>
            <div className={`input-field ${this.state.ddActive ? 'act' : ''}`}
              onClick={() => this.setDDActive()} id='dd-inp-for-direction'
            >
              <>
                {
                  <div className={`${!value.length && 'placeholder'}`}>
                    <span title={!value.length ? (placeholder || '') : value.join(', ')}>
                      {!value.length ? (placeholder || '') : value.join(', ')}
                    </span>
                  </div>
                }
              </>
              <div className={`cntrl-btn ${this.state.ddActive ? 'close-cntrl' : 'open'}`} />
            </div>
            {
              (options.length && this.state.ddActive) ?
                <div className='dd-options' style={this.props.ddMaxHeight ? { maxHeight: this.props.ddMaxHeight + 'px' } : {}}>
                  {
                    options.map((opt, i) =>
                      <div key={i} className={`dd-opt ${value.includes(opt) && 'multiselect-choosen'}`} onClick={() => {
                        this.props.onChange(value.includes(opt) ?
                          value.filter(v => v !== opt) : [...value, opt]);
                      }}
                        title={opt}
                      >
                        {opt}
                      </div>
                    )
                  }
                </div>
                :
                <></>
            }
          </div>
        }
        {
          type === 'number' &&
          <div className={`inp-container-text ${className}`}>
            <div className='col-inp-container'>
              {error &&
                <div className='err'>{error || ''}</div>
              }
              <input
                className={`${error && 'with-error'}`}
                type='number'
                value={value}
                disabled={disabled}
                onChange={(e) => {
                  if (e.target.value && ('' + e.target.value).length < 5) {
                    this.props.onChange(e.target.value);
                  }
                }}
                placeholder={placeholder}
              />
            </div>
          </div>
        }
        {
          ['text', 'password'].includes(type) &&
          <div className={`inp-container-text ${className}`}>
            <div className='col-inp-container'>
              <div className='err'>{error || ''}</div>
              <input
                className={`${error && 'with-error'}`}
                ref={this.setWrapperRef}
                type={this.state.showPassword ? 'text' : type}
                autoComplete={noAutocomplete ? 'new-password' : ''}
                value={value}
                disabled={disabled}
                placeholder={placeholder}
                onChange={(e) => {
                  if (this.props.onChange(e.target.value)) {
                    this.props.onChange(e.target.value);
                  }
                }}
              />
            </div>
            {
              type === 'password' && showPasswordIcon &&
              <div className='show-pass' onClick={() => this.setState({ showPassword: !this.state.showPassword })}>
                {
                  this.state.showPassword ?
                    <FontAwesomeIcon icon={['fas', 'eye-slash']} />
                    :
                    <FontAwesomeIcon icon={['fas', 'eye']} />
                }
              </div>
            }
            <div />
          </div>
        }
        {
          type === 'checkbox' &&
          <div
            onClick={() => this.props.onChange()}
            className={`input-checkbox ${disabled && 'disabled'} ${className} ${value ? 'act' : ''}`}
          />
        }
        {
          type === 'time' &&
          <div className='inp-container-text time'>
            <span>
              <input
                type='number'

                ref={this.timeHoursRef}
                placeholder='dd'
                onChange={(e) => this.onChangetime(0, e.target.value)}
              />
              <span>hours</span>
            </span>

            &nbsp; : &nbsp;
            <span>
              <input
                type='number'

                placeholder='hh'
                ref={this.timeMinutesRef}
                max={59}
                onChange={(e) => this.onChangetime(1, e.target.value)}
              />
              <span>minutes</span>
            </span>
          </div>
        }
        {
          type === 'dropdown-multilevel' &&
          <div className={`${className} ${this.state.ddActive ? 'act' : ''} ${disabled && 'disabled'} inp-container-gen`} ref={this.setWrapperRef}>
            <div className={`input-field ${this.state.ddActive ? 'act' : ''}`} onClick={() => this.setState({ ddActive: !this.state.ddActive })}>
              <>
                {
                  <div style={{ whiteSpace: 'pre-line' }}>{this.getMulttilevelValueText() || <span style={{ opacity: '0.6' }}>{placeholder || ''}</span>}</div>
                }
              </>
              {
                this.state.ddActive ?
                  <FontAwesomeIcon icon='chevron-up' />
                  :
                  <FontAwesomeIcon icon='chevron-down' />
              }
            </div>
            {
              options.length && this.state.ddActive &&
              <div className='dd-options'>
                {
                  options.map((opt, i) =>
                    <div key={i} className={`dd-opt ${this.isMultiSelectOptionActive(opt) && 'checked'}`}
                    >
                      <div className='opt-label' key={i} onClick={() => this.setMulttilevelValue('showSubOpt', { index: i, value: !opt.isActive })}>
                        {
                          !opt.options &&
                          <div
                            className={`input-checkbox ${opt.isActive ? 'act' : ''}`}
                          />
                        }
                        {opt.label}
                        {
                          opt.options &&
                          <>
                            {
                              opt.isActive ?
                                <FontAwesomeIcon
                                  className='auto-mrg-l'
                                  icon='angle-double-up'
                                />
                                :
                                <FontAwesomeIcon
                                  className='auto-mrg-l'
                                  icon='angle-double-down'
                                />
                            }
                          </>
                        }
                      </div>
                      {
                        opt.isActive && opt.inputs &&
                        <div className='inputs'>
                          {
                            opt.inputs.map((inp, ind) =>
                              <input
                                type='text'
                                key={ind}
                                value={inp.value}
                                onChange={(e) => this.onChangeInpMultilevel(e.target.value, i, ind)}
                                placeholder={inp.label}
                              />
                            )
                          }
                        </div>
                      }
                      {
                        opt.isActive && opt.options && opt.options.length &&
                        <>
                          <div className='sub-opt'>
                            <span className='btn-check-all' onClick={() => (this.selectUnselectAllSub(true, i))}>
                              Select All
                            </span>
                            <span>&nbsp;/&nbsp;</span>
                            <span className='btn-check-all' onClick={() => (this.selectUnselectAllSub(false, i))}>
                              Unselect All
                            </span>
                          </div>
                          {
                            opt.options.map((o, k) =>
                              <div className='sub-opt' key={k}>
                                <div
                                  onClick={() => this.setMulttilevelValue('checkSubOpt', { index: i, indexSub: k })}
                                  className={`input-checkbox ${o.isActive ? 'act' : ''}`}
                                />
                                {o.label}</div>
                            )
                          }
                        </>

                      }
                    </div>
                  )
                }
              </div>
            }
          </div>

        }
        {
          type === 'toggle' &&
          <div className='toggle' style={styleDD || {}}>
            <div className='toggle-button'>
              <input type='checkbox'
                className='checkbox'
                name={this.props.label}
                id={this.props.label}
                defaultChecked={this.props.defaultChecked}
                checked={this.props.checked}
                onChange={this.props.onChange}
              />
              <label className='label' htmlFor={this.props.label}>
                <span className='inner' />
                <span className='circle' />
              </label>
            </div>
          </div>
        }
      </>
    );
  }

}

export default Input;