import { userPool } from '../userPool';

function getTimeMode(localStorageItem) {
  if( localStorageItem && localStorageItem!== 'undefined' ) {
    return localStorageItem;
  }
  return 'UTC';
}

const initState = {
  cognitoUser: userPool.getCurrentUser(),
  awsCredentials: {},
  awsRegion: null,
  user: {
    group: '',
    username: '',
    email: '',
  },
  userLoaded: false,
  loginError: '',
  timeMode: getTimeMode(localStorage.getItem('EMS_active_timeMode')),
  preferredTzMode: 'UTC',
  client: null,
  mfaConfigured: null,
  showMap: true,
};

export default (state = initState, action) => {
  const handlers = {
    'LOGIN_SUCCESS': () => {
      const newState = {...state};
      const groups = action.userdata.getIdToken().payload['cognito:groups'];
      if (groups && groups[0]) {
        newState.user.group = groups[0];
      }else{
        newState.user.group = false;
      }
      newState.userLoaded = true;
      newState.user.username = action.userdata.getIdToken().payload['cognito:username'];
      newState.user.email = action.userdata.getIdToken().payload['email'];
      const tz = action.userdata.getIdToken().payload['custom:preferred_timezone'];
      const showMap = action.userdata.getIdToken().payload['custom:show_map'];
      if (tz) {
        newState.preferredTzMode = tz;
      }
      if (action.fromLogin) {
        newState.timeMode = tz || 'UTC';
        localStorage.setItem('EMS_active_timeMode', tz);
      }
      if (showMap) {
        newState.showMap = showMap === 'true';
      }

      return newState;
    },
    'LOGIN_ERROR': () => {
      return {...state, loginError: action.err};
    },
    'USER_LOADED': () => {
      return {...state, userLoaded: true};
    },
    'LOGOUT': () => {
      return {...state, user: { username: '' }, mfaConfigured: null, userLoaded: false};
    },
    'UPDATE_USER_MODULE': () => {
      delete action.type;
      return {...state, ...action};
    },
  };
  if (!handlers[action.type])
    return state;
  return handlers[action.type]();
};