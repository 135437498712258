import React from 'react';
import { Component } from 'react';
import { storeConnector } from 'store';

const strongRegex = new RegExp('^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');

class ChangePasswordPopup extends Component {

  constructor(props) {
    super(props);
    this.pswdInp = React.createRef();
    this.pswdInpNew = React.createRef();
    this.pswdInpRepeat = React.createRef();
    this.state = {
      oldPassword: '',
      newPassword: '',
      repeatPassword: '',
      showErrors: false,
    };
  }
  updateField(evt, field) {
    this.setState({
      [field]: evt.target.value,
    });
  }

  changePassword() {
    if(this.passwordValidation() === true) {
      this.setState({loading: true});
      this.props.actions.changePass({
        oldPass: this.state.oldPassword,
        newPass: this.state.newPassword,
      }).then(() => {
        this.props.close();
        this.props.actions.notify('Changes were saved successfully');
      }).catch((err)=>{
        this.setState({loading:false});
        this.props.actions.notifyError(`${err.message}`);
      });
    } else {
      this.setState({showErrors: true});
    }
  }

  passwordValidation(){
    if (!this.state.oldPassword.length){
      return 'Old password field can`t be empty';
    }
    if (this.state.newPassword.length < 8){
      return 'Password should contain 8 or more charachters';
    }
    if (!strongRegex.test(this.state.newPassword)) {
      return 'Password should contain number, capital letter, special symbol';
    }
    if (this.state.newPassword !== this.state.repeatPassword) {
      return 'Password and its confirmation are not identical';
    }
    return true;
  }

  render() {
    return (
      <div className='cover-conteiner'>
        <div className='control-popup-container'>
          <div className='title-profile'>Change password</div>
          <div className='popup-close' onClick={()=>this.props.close()} />
          <div className='profile-content pswd'>
            <div>Old password</div>
            <div>
              <input
                ref={this.pswdInput}
                style={{width:'100%'}}
                type='password'
                name='password'
                placeholder='********'
                onChange={(e) => this.updateField(e,'oldPassword')}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    if (!this.state.newPassword) {
                      this.pswdInpNew.current.focus();
                    } else if (!this.state.repeatPassword) {
                      this.pswdInpRepeat.current.focus();
                    } else {
                      this.changePassword();
                    }
                    e.preventDefault();
                  }
                }}/>
            </div>
            <div>New password</div>
            <div>
              <input
                ref={this.pswdInpNew}
                style={{width:'100%'}}
                type='password'
                name='password'
                placeholder='********'
                onChange={(e) => this.updateField(e,'newPassword')}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    if (!this.state.repeatPassword) {
                      this.pswdInpRepeat.current.focus();
                    } else if (!this.state.oldPassword) {
                      this.pswdInput.current.focus();
                    } else {
                      this.changePassword();
                    }
                    e.preventDefault();
                  }
                }}/>
            </div>
            <div>Confirm password</div>
            <div>
              <input
                ref={this.pswdInpRepeat}
                style={{width:'100%'}}
                type='password'
                name='password'
                placeholder='********'
                onChange={(e) =>this.updateField(e,'repeatPassword')}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    if (!this.state.oldPassword) {
                      this.pswdInput.current.focus();
                    } else if (!this.state.newPassword) {
                      this.pswdInpNew.current.focus();
                    } else {
                      this.changePassword();
                    }
                    e.preventDefault();
                  }
                }}/>
            </div>
            <div className='error'>
              {(this.state.showErrors && (this.passwordValidation() !== true)) ?
                this.passwordValidation() : ''}
            </div>
            <div>
              <button
                onClick={() => this.props.close()}
                style={{width:'100%'}}>
              Cancel
              </button>
            </div>
            <div>
              <button
                onClick={() => { this.changePassword(); }}
                style={{width:'100%'}}
              >
                Save
              </button>
            </div>
          </div>
          {
            this.state.loading &&
            <div className='chat-spinner-holder center'>
              <div className='lds-ripple'><div /><div /></div>
            </div>
          }

        </div>
      </div>
    );
  }
}

export default storeConnector(ChangePasswordPopup, {});