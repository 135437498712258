import React, { Component } from 'react';
import './Windmill.scoped.scss';
import {ReactComponent as D1} from 'assets/404-1-01.svg';
import {ReactComponent as D2} from 'assets/404-2-01.svg';

class Windmill extends Component {
  render() {
    const style = {
      ...(this.props.opacity ? { opacity: this.props.opacity } : {}),
      ...(this.props.top ? { top: this.props.top } : {}),
      ...(this.props.left ? { left: this.props.left } : {}),
      ...(this.props.scale ? { transform: `scale(${this.props.scale})` } : {}),
      ...(this.props.right ? { left: 'unset', right: this.props.right } : {}),
      ...(this.props.bottom ? { top: 'unset', bottom: this.props.bottom } : {}),
    };
    return (
      <div className='wind-mill' style={style}>
        <div className='part1'>
          <D1 />
        </div>
        <div className='part2'>
          <D2 />
        </div>
      </div>
    );
  }
}

export default Windmill;