import * as AWS from '@aws-sdk/client-kinesis';
import slsConfig from '../sls-stack-output.json';
import { dispatcher, store } from './';
import moment from 'moment';
import { authorization } from './actionCreators/authorization';

const WS_MESSAGE_PARSERS = {
  'UpdateUI': (_, data) =>
    processRecords(data.items.map(d => {
      d._topic = data.topic;
      return d;
    })),
  'UpdateUIFinished': (store) => {
    store.dispatch(dispatcher('UPDATE_SERVICE_MODULE', { updateFinished: true }));
    setTimeout(() => {
      store.dispatch(dispatcher('UPDATE_SERVICE_MODULE', { updateFinished: false }));
    }, 500);
  },
};

const decoder = new TextDecoder('utf-8');
const durationDict = {
  '5M': 5 * 60 * 1000,
  '15M': 15 * 60 * 1000,
  '30M': 30 * 60 * 1000,
  '1H': 60 * 60 * 1000,
};

function isTopic(topicName, topicValue) {
  return [topicName, `${topicName}UI`].includes(topicValue);
}

function processRecords(records) {
  function compare(a, b) {
    if (a.TS < b.TS)
      return 1;
    if (a.TS > b.TS)
      return -1;
    return 0;
  }
  const newState = { site: {}, vpp: {}, devices: {}, messages: {}, calendar: {} };
  records.forEach(record => {
    const topicParts = record._topic.split('/');

    const topic = topicParts[topicParts.length - 1];
    const msg = record;
    const moduleState = store.getState();
    console.log('++++++',moduleState.site.siteMeta );
    const state = Object.keys(store.getState()).reduce((acc, currentValue) => {
      acc = { ...acc, ...moduleState[currentValue] };
      return acc;
    }, {});
    const recordSN = msg.SN;
    if (isTopic('SiteMeta', topic)) {
      newState.vpp.VPPsSitesMeta = {
        ...state.VPPsSitesMeta,
        [msg.SN]: msg
      };
    }

    if (isTopic('Site', topic) && Object.keys(state.VPPsSites).length !== 0) {
      if (Object.keys(state.VPPsSites).includes(msg.SN)) {
        store.dispatch(dispatcher('UPDATE_VPP_MODULE', {
          VPPsSites: {
            ...state.VPPsSites,
            [msg.SN]: msg
          }
        }));
      }
    }
    if (isTopic('Alarms', topic) && Object.keys(state.alarmsForAllSites || {}).includes(msg.SN)) {
      newState.site.alarmsForAllSites = { ...state.alarmsForAllSites, [msg.SN]: msg.Alarms };
    }
    const siteMeta = state.siteMeta;
    if (!siteMeta) {
      return;
    }
    const allSNs = [siteMeta.SN, ...siteMeta.Units];
    if (!allSNs.includes(recordSN)) {
      return;
    }

    if (isTopic('PV', topic)) {
      const sn = msg.SN.split('_');
      const sitePV = state.sitePV;
      if (sitePV[sn[1]]) {
        const maxlength = {
          '1day': 5,
          '1hour': 120,
          '15min': 480,
        };
        sitePV[sn[1]].unshift(msg);
        sitePV[sn[1]].splice(maxlength[sn[1]], 1000);
        newState.site.sitePV = { ...sitePV };
      }
      newState.site.currentPV = msg;
    }

    if (isTopic('Site', topic) && siteMeta.SN === msg.SN) {
      if (!msg.SN.includes('SOC_Balancing')) {
        newState.site.currentSite = { ...msg, actual: true };
        ['chartSiteData', 'chartSOCData', 'chartTempData'].forEach(f => {
          const data = state[f];
          if (data && data.length) {
            const keys = Object.keys(data[0].value);
            const d = {
              date: msg.TS,
              value: {
              }
            };
            keys.forEach(k => d.value[k] = msg[k]);

            newState.site[f] = data.slice(1, data.length).concat(d);
          }
        });
      }
      else {
        newState.site.currentSOC_Balancing = msg;
      }
      ['battAmbTemp', 'battCellTemp'].forEach((f, i) => {
        if (
          state && state[`${f}SN`] === -1 && state[`${f}Chart`] &&
          state[`${f}Chart`].length
        ) {
          const data = state[`${f}Chart`];
          if (data && data.length) {
            const keys = i === 0 ? ['MaxAmbT', 'MinAmbT', 'AvgAmbT'] :
              ['MaxCellT', 'MinCellT', 'AvgCellT'];
            const d = {
              date: msg.TS,
              value: {
              }
            };
            keys.forEach(k => d.value[k] = +msg[k]);
            if (data.length) {
              const a = moment(data[0].TS);
              const b = moment(d.TS);
              if (b.diff(a) > durationDict[state[`${f}ChartDuration`]]) {
                data.shift();
              }
            }
            data.push(d);
            newState.site[`${f}Chart`] = data;
          }
        }
      });
    }
    if (isTopic('MKT', topic)) {
      if (!msg.SN.includes('Index')) {
        newState.site.siteMKT = msg;
        newState.site.currentMKT = msg;
      }
      else {
        const field_name = msg.SN.includes('_GEN_') ? 'GEN_Index' : 'LR_Index';
        newState.site.currentIndexes = { ...state.currentIndexes, [field_name]: msg };
      }
    }
    if (isTopic('SiteMeta', topic) && siteMeta.SN === msg.SN) {
      newState.site.siteMeta = msg;
    }

    if (isTopic('MSG', topic) && (siteMeta.Units.includes(msg.SN) || siteMeta.SN === msg.SN)) {
      const messages = state.messages;
      messages.push(msg);
      messages.sort(compare);
      newState.messages.messages = messages;
      if (state.messages.sysMsgsPaused === false && state.messages.messagesObj[msg.SN]) {
        const messagesObj = { ...state.messages.messagesObj };
        messagesObj[msg.SN].unshift(msg);
        newState.messagesObj = messagesObj;
      }
    }

    if (isTopic('Unit', topic) && siteMeta.Units.includes(msg.SN)) {
      const sns = state.siteMeta.Units;
      let unitTable = newState.devices.UnitTable ?
        newState.devices.UnitTable :
        (state.UnitTable ? JSON.parse(JSON.stringify(state.UnitTable)) : []);
      const unitSn = msg.SN === 'current' ? msg.SN_original : msg.SN;
      const nameIndex = sns.findIndex(el => el === unitSn);
      const unit = { ...{ ...msg, SN: unitSn }, UnitName: state.siteMeta.UnitNames[nameIndex] };
      const currentIndex = unitTable.findIndex(u => u.SN === unitSn);
      if (currentIndex === -1) {
        unitTable.push(unit);
        unitTable = unitTable.sort((a, b) => {
          const aIndex = sns.findIndex(el => el === a.SN);
          const bIndex = sns.findIndex(el => el === b.SN);
          if (aIndex > bIndex) {
            return 1;
          }
          if (aIndex < bIndex) {
            return -1;
          }
          return 0;
        });
      } else {
        unitTable[currentIndex] = unit;
      }
      newState.devices.UnitTable = unitTable;
      if ((state.currentUnit && state.currentUnit.SN === msg.SN) ||
        !state.currentUnit
      ) {
        newState.devices.currentUnit = {
          ...msg,
          UnitName: state.siteMeta.UnitNames[nameIndex]
        };
      }
      ['battAmbTemp', 'battCellTemp'].forEach((f, i) => {
        const chartSN = sns[state[`${f}SN`]];
        if (state && state[`${f}SN`] !== -1 && chartSN === unitSn && state[`${f}Chart`]) {
          const data = newState.site[`${f}Chart`] || state[`${f}Chart`];
          if (data && data.length) {
            const keys = i === 0 ? ['MaxAmbT', 'MinAmbT', 'AvgAmbT'] :
              ['MaxCellT', 'MinCellT', 'AvgCellT'];
            const d = {
              date: msg.TS,
              value: {
              }
            };
            keys.forEach(k => d.value[k] = +msg[k]);
            if (data.length) {
              const a = moment(data[0].TS);
              const b = moment(d.TS);
              if (b.diff(a) > durationDict[state[`${f}ChartDuration`]]) {
                data.shift();
              }
            }

            data.push(d);
            newState.site[`${f}Chart`] = data;
          }
        }
      });
    }
    if (isTopic('LOTO', topic)) {
      // debugger;
      const lotoTable = {...(newState.devices.lotoTable || state.lotoTable), [msg.SN]: msg};
      newState.devices.lotoTable = Object.keys(lotoTable).reduce((acc,cv) => {
        if (lotoTable[cv]?.Mode ==='lockout') {
          return { ...acc, [cv]: lotoTable[cv] };
        }
        return acc;
      }, {});
    }
    if (isTopic('Status', topic)) {
      const { statusDict } = state;
      newState.site.statusDict = {
        ...statusDict,
        [msg.SN === 'current' ? msg.SN_original : msg.SN]: Object.keys(msg).reduce((acc, cv) => {
          if (cv.includes('.')) {
            return { ...acc, [cv]: msg[cv] };
          }
          return acc;
        }, {})
      };
    }
    if (isTopic('PCS', topic)) {
      if (state.currentUnit && state.currentUnit.SN === msg.SN) {
        newState.devices.currentPCS = msg;
      }
      if (siteMeta.Units.includes(msg.SN)) {
        newState.devices.pcsTable = state.pcsTable;
        newState.devices.pcsTable[msg.SN] = msg;
        newState.devices.PCSDict = state.PCSDict || {};
        newState.devices.PCSDict[msg.SN] = msg;
      }
    }
    if (isTopic('Rack', topic) && siteMeta.Units.includes(msg.SN)) {
      let newRack = null;
      if (msg) {
        newRack = msg;
        for (let key in newRack) {
          if (typeof newRack[key] === 'string' && newRack[key].includes('[')) {
            try {
              newRack[key] = JSON.parse(newRack[key]);
            } catch {
              newRack[key] = newRack[key].replace(/(\[|\])/g, '').split(',');
            }
          }
        }
      }
      if (state.currentUnit && state.currentUnit.SN === msg.SN) {
        newState.devices.currentRack = newRack;
      }
      newState.devices.rackDict = state.rackDict;
      newState.devices.rackDict[msg.SN] = newRack;
    }
    if (isTopic('Battery', topic) && siteMeta.Units.includes(msg.SN)) {
      newState.devices.batteryTable = state.batteryTable;
      newState.devices.batteryTable[msg.SN] = msg;
      newState.devices.batteryDict = state.batteryDict || {};
      newState.devices.batteryDict[msg.SN] = msg;
    }
    if (isTopic('Faults', topic) && state.alarmsAndWarnings && state.alarmsAndWarnings[msg.SN]) {
      const alarmAndWarn = state.alarmsAndWarnings;
      alarmAndWarn[msg.SN] = msg;
      newState.messages.alarmsAndWarnings = alarmAndWarn;
    }
    if (isTopic('Alarms', topic) && allSNs.includes(msg.SN)) {
      const alarms = JSON.parse(JSON.stringify(state.alarms));
      newState.messages.alarms = { ...alarms, [msg.SN]: msg };
    }
    if (isTopic('Event', topic) && siteMeta.SN === msg.SN) {
      let db = newState.calendar.eventsFromDB ?
        newState.calendar.eventsFromDB : [...state.eventsFromDB];
      const event = msg;

      const deleteIndex = db.findIndex((e) =>
        e.startDate === event.startDate && e.startTime === event.startTime
      );
      db.splice(deleteIndex, 1);

      const index = db.findIndex((e) => +e.ID === +event.ID);
      if (event.status === 'removed') {
        if (index > -1) {
          db.splice(index, 1);
        }
      } else {
        if (index > -1) {
          db[index] = event;
        } else {
          db.push(event);
        }
      }
      newState.calendar.eventsFromDB = db;
    }
  });

  Object.keys(newState).forEach(module => {
    store.dispatch(dispatcher(`UPDATE_${module.toUpperCase()}_MODULE`, newState[module]));
  });
}

async function createWebsocketClient() {
  return new Promise((resolve, reject) => {
    const state = store.getState();
    if (state.service.websocket) {
      console.log('Websocket already exists, stoping.');
      return;
    }

    const websocket = new WebSocket(slsConfig.ServiceEndpointWebsocket + '?idToken=' +
      state.user.cognitoUser?.signInUserSession?.idToken?.jwtToken);
    websocket.addEventListener('open', () => {
      store.dispatch(dispatcher('UPDATE_SERVICE_MODULE', { websocket: websocket }));

      const fetchUpdate = () => {
        const websocket = store.getState().service.websocket;

        if (websocket) {
          websocket.send(JSON.stringify({ action: 'UpdateUI' }));
          setTimeout(fetchUpdate, 5000);
        } else {
          store.dispatch(dispatcher('UPDATE_SERVICE_MODULE', { timeout: false }));
        }
      };

      if (!store.getState().service.timeout) {
        fetchUpdate();
        store.dispatch(dispatcher('UPDATE_SERVICE_MODULE', { timeout: true }));
      }

      resolve();
    });
    websocket.addEventListener('error', () => {
      store.dispatch(dispatcher('UPDATE_SERVICE_MODULE', { websocket: false }));
      reject();
    });
    websocket.addEventListener('close', () => {
      store.dispatch(dispatcher('UPDATE_SERVICE_MODULE', { websocket: false }));

      setTimeout(createWebsocketClient, 0);

      reject();
    });

    websocket.addEventListener('message', (message) => {
      // const r = Math.random();

      // console.time('Websocket Stream Processing. JSON parse ' + r);
      const data = JSON.parse(message.data);
      // console.timeEnd('Websocket Stream Processing. JSON parse ' + r);
      // console.time('Websocket Stream Processing. Processing ' + data.topic + ' ' + r);

      const events = data.events ? data.events : [data];

      // console.log('~!Websocket message', data);

      let processingMethod = WS_MESSAGE_PARSERS[data.event];

      if (processingMethod) events.forEach(e => processingMethod(store, e));

      // console.timeEnd('Websocket Stream Processing. Processing ' + data.topic + ' ' + r);
    });
  });
}

export async function createIotClient(/*iotEp*/) {
  await createWebsocketClient();

  const state = store.getState();
  let interval = state.interval;
  if (interval) {
    console.log('Interval already exists, stoping.');
    return;
  }
  const kinesis = new AWS.Kinesis({
    apiVersion: '2013-12-02',
    credentials: state.user.awsCredentials,
    region: state.user.awsRegion,
  });

  const shardsResponse = await kinesis.listShards({
    StreamName: slsConfig.RTMSGDataKinesisStream,
  });

  const shards = shardsResponse.Shards;

  let shardIterators = await Promise.all(shards.map(async (shard) => {
    return kinesis.getShardIterator({
      ShardId: shard.ShardId,
      StreamName: slsConfig.RTMSGDataKinesisStream,
      ShardIteratorType: 'LATEST',
    }).then(r => r.ShardIterator);
  }));

  console.log('~!!shardIterators', shardIterators);

  store.dispatch(dispatcher('UPDATE_MESSAGES_MODULE', { connectionAlert: false }));

  const collectData = async () => {
    const r = Math.random();
    console.time('Kinesis Stream Processing ' + r);

    const kinesis = new AWS.Kinesis({
      apiVersion: '2013-12-02',
      credentials: state.user.awsCredentials,
      region: state.user.awsRegion,
    });

    const results = await Promise.all(shardIterators.map(async iterator => {
      return kinesis.getRecords({
        ShardIterator: iterator
      });
    })).catch(async e => {
      if (e.code === 'ExpiredIteratorException') {
        console.log('~!Got expired shard iterators. Fetching new ones.');
        shardIterators = await Promise.all(shards.map(async (shard) => {
          return kinesis.getShardIterator({
            ShardId: shard.ShardId,
            StreamName: slsConfig.RTMSGDataKinesisStream,
            ShardIteratorType: 'LATEST',
          }).then(r => r.ShardIterator);
        }));
        return null;
      } else {
        throw e;
      }
    });

    console.timeLog('Kinesis Stream Processing ' + r);

    if (results) {
      const allRecords = results.reduce((a, r) => a.concat(r.Records), []);

      // const approximateRecords = Object.values(allRecords
      //   .sort((a, b) => b.ApproximateArrivalTimestamp - a.ApproximateArrivalTimestamp)
      //   .reduce((records, r, i) => {
      //     const keyParts = r.PartitionKey.split('/');
      //     const topic = keyParts[keyParts.length - 1];

      //     if (['Site', 'Config', 'App', 'Event'].includes(topic)) {
      //       records[i] = r;
      //     } else if (!records[r.PartitionKey]) {
      //       records[r.PartitionKey] = r;
      //     }

      //     return records;
      //   }, {}));
      const records = Object.values(allRecords
        .map(r => {
          const d = JSON.parse(decoder.decode(r.Data));
          d._topic = r.PartitionKey;
          return d;
        })
        .sort((a, b) => a.TS === b.TS ? 0 : (a.TS > b.TS ? -1 : 1))
        .reduce((records, r, i) => {
          const key = r._topic + r.SN + r.Description;
          const topicParts = r._topic.split('/');
          const topic = topicParts[topicParts.length - 1];

          if (!records[key]) {
            records[key] = r;
          } else if (topic === 'Event') {
            records[i] = r;
          }

          return records;
        }, {}));

      // console.log(allRecords.length, approximateRecords.length, records.length);
      console.log(allRecords.length, records.length);
      console.timeLog('Kinesis Stream Processing ' + r);
      processRecords(records);

      shardIterators = results.map(r => r.NextShardIterator).filter(s => !!s);
    }
    console.timeEnd('Kinesis Stream Processing ' + r);
  };

  const collectDataWrapper = async () => {
    try {
      await collectData();
      if (store.getState().service.interval) {
        setTimeout(collectDataWrapper, 2000);
      }
    } catch (e) {
      console.error(e);
      store.dispatch(dispatcher('UPDATE_SERVICE_MODULE', { interval: false }));
      if (e.code === 'CredentialsError') {
        store.dispatch(authorization.initUser(true));
      } else {
        setTimeout(createIotClient, 1000);
      }
    }
  };

  setTimeout(collectDataWrapper, 0);

  store.dispatch(dispatcher('UPDATE_SERVICE_MODULE', { interval: true }));
}