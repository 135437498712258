import React, { Component } from 'react';
import './Notifications.scoped.scss';
import { storeConnector } from 'store';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);

class Notifications extends Component {

  render() {
    return (
      this.props.tmpNotifications &&
        <div className='notifications-list' style={{pointerEvents:'none'}}>
          {
            this.props.tmpNotifications.map((n, i) =>
              <div key={n.id} className={`notification ${n.type} ${n.className}`}
                style={{pointerEvents:'all'}}
              >
                <div>
                  <div>{n.message}</div>
                  <div className='close-notification'
                    onClick={() => this.props.actions.closeNotification(n.id)}
                  >&times;</div>
                </div>
                {
                  n.yesCallBack &&
                  <div>
                    <button onClick={() => {
                      this.props.actions.closeNotification(n.id);
                      n.yesCallBack();
                    }}>
                      <FontAwesomeIcon icon='check' />
                    </button>
                    <button onClick={() => {
                      this.props.actions.closeNotification(n.id);
                      n.noCallBack();
                    }}>
                      <FontAwesomeIcon icon='times' />
                    </button>
                  </div>
                }
              </div>
            )
          }
        </div>
    );
  }
}

export default storeConnector(Notifications, {
  service: ['tmpNotifications'],
});