import { callRESTThrottled } from '../../utils';
import { dispatcher, store } from '../';

export const control = {
  sendCommand(commands, SN) {
    return function (dispatch) {
      Promise.all(commands.map(cmd => {
        console.log('sendCommand :', cmd);
        return callRESTThrottled(
          'POST',
          '/config/serve',
          {
            action: 'sendCommand',
            description: 'CMD',
            cmd: cmd,
            topicPrefix: store.getState().site.siteMeta.TopicPrefix,
            source: '',
            sn: SN,
            version: '0.1',
          }
        );
      })).then(res => {
        console.log('then :', res);
      });
    };
  },

  setTimeMode(timeMode) {
    return function (dispatch) {
      dispatch(dispatcher('UPDATE_USER_MODULE', { timeMode }));
      localStorage.setItem('EMS_active_timeMode', timeMode);
    };
  },

  getSystemConfig(SN, ControlMode) {
    return function (dispatch) {
      return new Promise((resolve) => {
        callRESTThrottled('POST',
          '/config/serve',
          {
            action: 'getTableItems',
            table: 'Config_Active',
            extraParams: {
              'ScanIndexForward': false,
              'ExpressionAttributeValues': {
                ':desc': `${ControlMode}`,
                ':sn': SN
              },
              KeyConditionExpression: 'Description = :desc AND SN = :sn',
              FilterExpression: 'attribute_not_exists(AppId)',
            },
          }).then((r) => {
          resolve(r);
        });
      });
    };
  },
  saveSystemConfig(SN, item) {
    return function (dispatch) {
      return new Promise((resolve) => {
        callRESTThrottled('POST',
          '/config/serve',
          {
            action: 'saveSystemConfig',
            item: item,
            test: true,
            topicPrefix: store.getState().site.siteMeta.TopicPrefix,
            source: '',
            sn: SN,
            version: '0.1',
          }).then((r) => {
          console.log('ok');
          resolve(r);
        });
      });
    };
  },
};