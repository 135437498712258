import { callRESTThrottled } from '../../utils';
import { dispatcher, store } from '../';
import moment from 'moment';

const UNITS_PER_REQUEST_5 = 500;
const NEXT_REQUEST_IN_1050_MS = 900;

const waitToGetNextPack = () => new Promise(r => setTimeout(r, NEXT_REQUEST_IN_1050_MS));
const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

export const messagesAlertsWarnings = {

  getMessages(meta, extraParams = {}) {
    function compare(a, b) {
      if (a.TS < b.TS)
        return 1;
      if (a.TS > b.TS)
        return -1;
      return 0;
    }
    return async (dispatch) => {
      const key = Math.random();
      dispatch(dispatcher('UPDATE_MESSAGES_MODULE', { msgKey: key }));
      if (meta.TopicPrefix && meta.Units.length !== 0) {
        let requestedIds = [...meta.Units];

        if (!requestedIds.includes(meta.SN)) {
          requestedIds.unshift(meta.SN);
        }
        const mappedRestArr = [];
        requestedIds = extraParams.sn ? [extraParams.sn] : requestedIds;
        requestedIds.forEach((req, i) => {
          if (!((i + 1) % UNITS_PER_REQUEST_5)) {
            mappedRestArr.push(requestedIds.slice(i + 1 - UNITS_PER_REQUEST_5, i + 1));
          } else if (requestedIds.length - i ===
            requestedIds.length % UNITS_PER_REQUEST_5
          ) {
            mappedRestArr.push(requestedIds.slice(i, requestedIds.length));
          }
        });
        const prevObj = store.getState().messages.messagesObj ?
          JSON.parse(JSON.stringify(store.getState().messages.messagesObj)) : {};
        const messageArr = [];
        const messageDict = extraParams.loadMore ? prevObj : {};
        if (extraParams.from && extraParams.to &&
          !moment(extraParams.from).isBefore(extraParams.to)
        ) {
          if (key === store.getState().messages.msgKey) {
            dispatch(dispatcher('UPDATE_MESSAGES_MODULE', { messages: [], messagesObj: {} }));
          }
          return;
        }
        await asyncForEach(mappedRestArr, async (msgPack, indx) => {
          await asyncForEach(msgPack, async sn => {
            const expr = {
              ...(
                extraParams.from ?
                  { ':from': extraParams.from } :
                  {}),
              ...(
                ((extraParams.loadMore && prevObj[sn] &&
                  prevObj[sn][prevObj[sn].length - 1]) || extraParams.to
                ) ?
                  {
                    ':to': extraParams.to ||
                      prevObj[sn][prevObj[sn].length - 1].TS,
                  } : {}
              ),
            };
            const { result } = await callRESTThrottled('POST', '/config/serve', {
              action: 'getTableItems',
              table: 'MSG',
              extraParams: {
                'ScanIndexForward': false,
                'ExpressionAttributeValues': {
                  ':v': sn,
                  ...expr,
                },
                'KeyConditionExpression': `SN = :v ${(expr[':from'] || expr[':to']) ? 'AND' : ''} ${(expr[':from'] && expr[':to']) ? 'TS BETWEEN :from AND :to' : (expr[':from'] ? 'TS >= :from' : (expr[':to'] ? 'TS < :to' : ''))}`,

                ...((extraParams.from && extraParams.to) ? {} :
                  { 'Limit': Math.round(100 / requestedIds.length) }),
              },
            });
            result.forEach(el => {

              if (!messageDict[sn]) {
                messageDict[sn] = [];
              }
              if (el.payload) {
                messageArr.push(el.payload);
                messageDict[sn].push(el.payload);
              }
              else {
                messageArr.push(el);
                messageDict[sn].push(el);
              }
            });
            const finalMessageArr = [...messageArr.sort(compare)];
            if (key === store.getState().messages.msgKey) {
              dispatch(dispatcher('UPDATE_MESSAGES_MODULE',
                { messages: finalMessageArr, messagesObj: messageDict })
              );
            }
          });
          await waitToGetNextPack();
        });
        return {};
      }
    };
  },
  getAlwaysSortedMessages(siteMeta, params = {}) {
    let { msgServiceDict } = store.getState().messages;
    return async (dispatch) => {
      const meta = store.getState().site.siteMeta;
      const key = Math.random();
      dispatch(dispatcher('UPDATE_MESSAGES_MODULE', {
        ...(params.loadMore ? {} : {
          messagesObj: {},
          msgServiceDict: {
            loading: true,
          }
        }),
        msgKeySorted: key
      }));
      let requestedIds = [...meta.Units];
      if (!requestedIds.includes(meta.SN)) {
        requestedIds.unshift(meta.SN);
      }
      requestedIds = params.sn ? [params.sn] : requestedIds;
      const timeIntervalForOneSn = 3600 * 6;
      const minMsgsNum = 100;
      const extraParams = { ...params };
      if (extraParams.loadMore) {
        extraParams.to = msgServiceDict.currToDate;
      }

      const prevObj = store.getState().messages.messagesObj ?
        JSON.parse(JSON.stringify(store.getState().messages.messagesObj)) : {};
      const messageDict = extraParams.loadMore ? prevObj : {};
      if (params.from && params.to &&
        !(moment.utc(params.from).isBefore(moment.utc(params.to)))
      ) {
        if (key === store.getState().messages.msgKeySorted) {
          dispatch(dispatcher('UPDATE_MESSAGES_MODULE', { messagesObj: {}, msgServiceDict: {} }));
        }
        return;
      }
      let fromDate = extraParams.from;
      let toDate = extraParams.to;
      let msgAlreadyLoaded = 0;
      if (extraParams.to) {
        fromDate = moment.utc(toDate).subtract(timeIntervalForOneSn, 'seconds').format();
      } else {
        toDate = moment.utc().format();
        fromDate = moment.utc(toDate).subtract(timeIntervalForOneSn, 'seconds').format();
      }
      if (extraParams.from && moment.utc(fromDate).isBefore(moment.utc(extraParams.from))) {
        fromDate = extraParams.from;
      }
      msgServiceDict = {
        ...msgServiceDict,
        fromDateInit: params.from,
        toDateInit: params.to,
        currFromDate: fromDate,
        currToDate: toDate,
        loading: true,
        currReqMsgsLength: msgAlreadyLoaded,
        finishedReqs: requestedIds.reduce((acc, cv) => {
          return { ...acc, [cv]: false };
        }, {})
      };
      dispatch(dispatcher('UPDATE_MESSAGES_MODULE', { msgServiceDict }));
      const runReqs = (from, to) => {
        requestedIds.forEach(async sn => {
          const expr = {
            ':from': from,
            ':to': to,
          };
          const { result } = await callRESTThrottled('POST', '/config/serve', {
            action: 'getTableItems',
            table: 'MSG',
            extraParams: {
              'ScanIndexForward': false,
              'ExpressionAttributeValues': {
                ':v': sn,
                ...expr,
              },
              'KeyConditionExpression':
                `SN = :v ${(expr[':from'] || expr[':to']) ? 'AND' : ''} TS BETWEEN :from AND :to`,
            },
          });
          let msgCount = 0;
          result.forEach(el => {
            if (!messageDict[sn]) {
              messageDict[sn] = [];
            }
            if (el.payload) {
              messageDict[sn].push(el.payload);
              msgCount += 1;
            }
            else {
              messageDict[sn].push(el);
              msgCount += 1;
            }
          });
          if (key === store.getState().messages.msgKeySorted) {
            const { msgServiceDict } = store.getState().messages;
            dispatch(dispatcher('UPDATE_MESSAGES_MODULE', {
              messagesObj: messageDict,
              msgServiceDict: {
                ...msgServiceDict,
                currReqMsgsLength: (msgServiceDict.currReqMsgsLength || 0) +
                  msgCount,
                finishedReqs: { ...msgServiceDict.finishedReqs, [sn]: true }
              }
            }));
            const newMsgServiceDict = { ...store.getState().messages.msgServiceDict };
            if (!Object.values(newMsgServiceDict.finishedReqs).includes(false)) {
              if (minMsgsNum > newMsgServiceDict.currReqMsgsLength
                && from !== newMsgServiceDict.fromDateInit) {
                let toDateNew = newMsgServiceDict.currFromDate;
                let fromDateNew = moment.utc(toDateNew).subtract(timeIntervalForOneSn, 'seconds')
                  .format();
                if (newMsgServiceDict.fromDateInit &&
                  moment.utc(fromDateNew)
                    .isBefore(moment.utc(newMsgServiceDict.fromDateInit))
                ) {
                  fromDateNew = newMsgServiceDict.fromDateInit;
                }
                dispatch(dispatcher('UPDATE_MESSAGES_MODULE', {
                  msgServiceDict: {
                    ...msgServiceDict,
                    currFromDate: fromDateNew,
                    currToDate: toDateNew,
                  }
                }));
                runReqs(fromDateNew, toDateNew);
              } else {
                dispatch(dispatcher('UPDATE_MESSAGES_MODULE', {
                  msgServiceDict: {
                    ...msgServiceDict,
                    loading: false,
                  }
                }));
              }
            }
          }
        });
      };
      runReqs(fromDate, toDate);
    };
  },

  loadAlertsAndWarnings(sns) {
    return function (dispatch) {
      if (sns.length !== 0) {
        const restArr = [];
        sns.forEach(element => {
          restArr.push(callRESTThrottled(
            'POST',
            '/config/serve',
            {
              action: 'getTableItems',
              table: 'Faults',
              totalLimit: 1,
              extraParams: {
                'ScanIndexForward': false,
                'ExpressionAttributeValues': {
                  ':v': element,
                },
                'KeyConditionExpression': 'SN = :v',
              },
            }
          ));
        });
        return new Promise((resolve, reject) => {
          Promise.all(restArr).then((res) => {
            const obj = {};
            res.forEach(element => {
              console.log(element);
              if (element.result[0] && element.result[0].SN) {
                obj[element.result[0].SN] = element.result[0];
              }
            });
            dispatch(dispatcher('UPDATE_MESSAGES_MODULE', { alarmsAndWarnings: obj }));
            resolve();
          });
        });
      }
    };
  },

  loadAlarms(sns) {
    return function (dispatch) {
      if (sns.length !== 0) {
        const restArr = [];
        sns.forEach(element => {
          restArr.push(callRESTThrottled(
            'POST',
            '/config/serve',
            {
              action: 'getTableItems',
              table: 'Alarms',
              totalLimit: 1,
              extraParams: {
                'ScanIndexForward': false,
                'ExpressionAttributeValues': {
                  ':v': element,
                },
                'KeyConditionExpression': 'SN = :v',
              },
            }
          ));
        });
        return new Promise((resolve, reject) => {
          Promise.all(restArr).then((res) => {
            const obj = {};
            res.forEach(element => {
              if (element.result[0] && element.result[0].SN) {
                obj[element.result[0].SN] = element.result[0];
              }
            });
            dispatch(dispatcher('UPDATE_MESSAGES_MODULE', { alarms: obj }));
            resolve();
          });
        });
      }
    };
  },
  alarmACK(data) {
    return function (dispatch) {
      return new Promise(async (resolve, reject) => {
        const { result } = await callRESTThrottled(
          'POST',
          '/config/v2/serve',
          {
            action: 'alarmACK',
            ...data,
          }
        );
        const alarms = { ...store.getState().messages.alarms };
        if (result.success && alarms[data.sn]?.Alarms[data.alarmIndex]) {
          const operatorName = store.getState().user.user.username;
          alarms[data.sn].Alarms[data.alarmIndex].Acknowledged = operatorName;
          dispatch(dispatcher('UPDATE_MESSAGES_MODULE', { alarms }));
        }
        return resolve();
      });
    };
  },
};