const initState = {
  currentSite: null,
  siteMeta: null,
  siteStatus: {},
  alarmsForAllSites: null,
  sitePV: {},
  currentMKT: {},
  currentPV: null,
  awardsDates: [],
  statusDict: null,
  statusConfigDict: {},
  chartSiteData: null,
  chartSOCData: null,
  chartTempData: null,
  battAmbTempChart: [],
  battAmbTempChartDuration: '15M',
  battAmbTempSN: -1,
  battCellTempChart: [],
  battCellTempChartDuration: '15M',
  battCellTempSN: -1,
};

export default (state = initState, action) => {
  const handlers = {
    'UPDATE_SITE_MODULE': () => {
      delete action.type;
      return { ...state, ...action };
    },
  };
  if (!handlers[action.type])
    return state;
  return handlers[action.type]();
};