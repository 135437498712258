import { callRESTThrottled, filterEventsByTimeFrame } from '../../utils';
import { dispatcher, store } from '../';
import moment from 'moment-timezone';
import tzlookup from 'tz-lookup';

/*
eventFromLog:{
    eventID: String,
    SN: String,
    status: String, // started, completed
}
eventFromDB:{
    ID: String,             // UUID
    SN: String,             // SN of Site
    startDate: MM/DD/YYYY,  // convert to date
    startTime: HH:MM:SS,    // convert to date
    stopTime: HH:MM:SS,     // convert to date
    color: String,          // #ffb623
    period: String,         // day, month, weekend, weekday
    recurrence: String,     // "", ntimes, tilldate, forever
    repeatTime: Int,        // count of repear time
    tillDate: Int,          // convert to date
    startCommands:[String],
    stopCommands:[String],
    stack:id,
}
*/

export const calendar = {

  getAllEvents() {
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        Promise.all([
          callRESTThrottled('POST', '/config/serve', {
            action: 'getTableItems',
            table: 'Event',
            extraParams: {
              'ScanIndexForward': false,
              'ExpressionAttributeValues': {
                ':v': store.getState().site.siteMeta.SN.concat('_current'),
              },
              KeyConditionExpression: 'SN = :v',
            },
          }),
          callRESTThrottled('POST', '/config/serve', {
            action: 'getTableItems',
            table: 'EventLog',
            extraParams: {
              'ScanIndexForward': false,
              'ExpressionAttributeValues': {
                ':v': store.getState().site.siteMeta.SN,
              },
              KeyConditionExpression: 'SN = :v',
            },
          })
        ]).then(r => {
          const res = r[0].result.filter((event) => {
            if (event.status === 'removed') { return false; }
            return true;
          });
          dispatch({ type: 'SET_EVENTS_FROM_DB', events: res });
          dispatch({ type: 'SET_EVENTS_FROM_LOG', events: r[1].result });
          resolve(res);
        });
      });
    };
  },
  getEventsForCalendar(timeFrame) {
    return function (dispatch) {
      const tz = tzlookup(store.getState().site.siteMeta.GPSLat,
        store.getState().site.siteMeta.GPSLong
      );

      const now = moment().tz(tz);
      const selectedEventID = store.getState().calendar.selectedEventID;

      let dbEvents =
        filterEventsByTimeFrame(store.getState().calendar.eventsFromDB, timeFrame, tz);
      let logEvents = store.getState().calendar.eventsFromLog;

      //////// fix time format
      dbEvents = dbEvents.map(e => {
        const newEvent = { ...e };
        if (e.startTime) {
          const startArr = e.startTime.split(':');
          newEvent.startTime = moment().hour(startArr[0]).minutes(startArr[1]).seconds(startArr[2])
            .format('HH:mm:ss');
        }
        if (e.stopTime) {
          const stopArr = e.stopTime.split(':');
          newEvent.stopTime = moment().hour(stopArr[0]).minutes(stopArr[1]).seconds(stopArr[2])
            .format('HH:mm:ss');
        }
        return newEvent;
      });

      dbEvents = dbEvents.filter((event) => {
        if (+event.ID === +selectedEventID) { return false; }
        const stopTime = moment(event.stop).tz(tz);
        if (stopTime < now) { return false; }
        return true;
      });
      if (timeFrame) {
        const timeFrameStart = moment.tz(timeFrame[0], tz).hour(0).minute(0).seconds(0);
        logEvents = logEvents.filter((event) => {
          const stopTime = moment(event.end_ts).tz(tz);
          if (timeFrameStart > stopTime) { return false; }
          const startTime = moment(event.start_ts).tz(tz);
          if (startTime > now) { return false; }
          return true;
        });
        logEvents = logEvents.map((l) => ({
          ...l,
          event_id: `${l.event_id}_${moment(l.start_ts).valueOf()}`,
        }));
      }

      let allEvents = _convertEvents([...logEvents, ...dbEvents],
        '', tz,
        store.getState().calendar.stackList,
        store.getState().calendar.selectedEventID
      );

      return allEvents;
    };
  },
  convertEvents(baseEvents, className) {
    return function (dispatch) {
      const tz = tzlookup(store.getState().site.siteMeta.GPSLat,
        store.getState().site.siteMeta.GPSLong
      );
      return _convertEvents(baseEvents, className, tz, store.getState().calendar.stackList,
        store.getState().calendar.selectedEventID
      );
    };
  },
  isInvalidEvent(selectedEvent) {
    return function (dispatch) {

      const tz = tzlookup(store.getState().site.siteMeta.GPSLat,
        store.getState().site.siteMeta.GPSLong
      );
      const now = moment().tz(tz);
      const timeFrame = [
        now.clone().date(now.date() - 1).format('YYYY-MM-DD'),
        now.clone().month(now.month() + 1).format('YYYY-MM-DD'),
      ];

      const dbEvents =
        filterEventsByTimeFrame(store.getState().calendar.eventsFromDB, timeFrame, tz);

      const selectedEventArray = filterEventsByTimeFrame(selectedEvent, timeFrame, tz);
      const busyTime = dbEvents.filter(event => {
        if (selectedEvent && +event.ID === +selectedEvent.ID) { return false; }

        if (moment.tz(event.start, tz) < moment().tz(tz)) {
          return false;
        }
        return true;
      });

      for (let i = 0; i < selectedEventArray.length; i++) {
        const frame = selectedEventArray[i];

        const frameStart = moment.tz(frame.start, tz);
        const frameEnd = moment.tz(frame.stop, tz);

        for (let j = 0; j < busyTime.length; j++) {
          const event = busyTime[j];

          const eventEnd = moment.tz(event.stop, tz);
          if (eventEnd < frameStart) { continue; }

          const eventStart = moment.tz(event.start, tz);

          if (frameStart <= eventStart && eventStart <= frameEnd) {
            console.log('frameStart < eventStart < frameEnd');
            return event;
          }
          if (frameStart <= eventEnd && eventEnd <= frameEnd) {
            console.log('frameStart < eventEnd < frameEnd');
            return event;
          }
          if (eventStart <= frameStart && frameStart <= eventEnd) {
            console.log('eventStart < frameStart < eventEnd');
            return event;
          }
          if (eventStart <= frameEnd && frameEnd <= eventEnd) {
            console.log('eventStart < frameEnd < eventEnd');
            return event;
          }
          if (eventStart < frameEnd) { break; }
        }
      }
      return false;
    };
  },
  saveEvent(item) {
    return function (dispatch) {
      return new Promise((resolve) => {

        const index = store.getState().calendar.eventsFromDB.findIndex((ev, index) => {
          if (+ev.ID === +item.ID) { return true; }
          return false;
        });

        Object.keys(item).forEach((key) => {
          if (Array.isArray(item[key])) {
            item[key] = item[key].filter((str) => {
              return !!str;
            });
          } else if (!item[key]) {
            delete item[key];
          }
        });
        dispatch({ type: 'SAVE_EVENT', index: index, event: item });
        callRESTThrottled('POST',
          '/config/serve',
          {
            action: 'saveEvent',
            item: item,
            topicPrefix: store.getState().site.siteMeta.TopicPrefix,
            sn: store.getState().site.siteMeta.SN,
            TS: new Date().getTime(),
          }).then((r) => {
          dispatch(dispatcher('SAVE_EVENT_COMPLETE'));
          resolve(r);
        });
      });
    };
  },
  deleteEvent(event) {
    return function (dispatch) {
      return new Promise((resolve) => {
        const index = store.getState().calendar.eventsFromDB.findIndex((ev) => {
          if (+ev.ID === +event.ID) { return true; }
          return false;
        });
        dispatch({ type: 'DELETE_EVENT', index });
        event.status = 'removed';
        callRESTThrottled('POST',
          '/config/serve',
          {
            action: 'saveEvent',
            item: event,
            topicPrefix: store.getState().site.siteMeta.TopicPrefix,
            sn: store.getState().site.siteMeta.SN,
          }).then((r) => {
          resolve(r);
        });
      });
    };
  },

  selectEvent(id, clickedDate) {
    return function (dispatch) {
      dispatch(dispatcher('SELECT_EVENT', { id }));
      dispatch(dispatcher('SELECT_EVENT_DATE', { date: clickedDate }));
    };
  },
  deselectEvent() {
    return function (dispatch) {
      dispatch(dispatcher('DESELECT_EVENT'));
    };
  },
  updateSelectedEvent(event) {
    return function (dispatch) {
      dispatch({ type: 'UPDATE_SELECTED_EVENT', event });
    };
  },
  applySelectedEvent() {
    return function (dispatch) {

    };
  },
  saveEventToStoreOnly(item) {
    return function (dispatch) {
      return new Promise((resolve) => {

        const index = store.getState().calendar.eventsFromDB.findIndex((ev, index) => {
          if (+ev.ID === +item.ID) { return true; }
          return false;
        });

        Object.keys(item).forEach((key) => {
          if (Array.isArray(item[key])) {
            item[key] = item[key].filter((str) => {
              return !!str;
            });
          } else if (!item[key]) {
            delete item[key];
          }
        });
        dispatch({ type: 'SAVE_EVENT', index: index, event: item });
        dispatch(dispatcher('SAVE_EVENT_COMPLETE'));
      });
    };
  },
  saveEventToDBOnly(item) {
    return function (dispatch) {
      return new Promise((resolve) => {
        callRESTThrottled('POST',
          '/config/serve',
          {
            action: 'saveEvent',
            item: item,
            topicPrefix: store.getState().site.siteMeta.TopicPrefix,
            sn: store.getState().site.siteMeta.SN,
            TS: new Date().getTime(),
          }).then((r) => {
          dispatch(dispatcher('SAVE_EVENT_COMPLETE'));
          resolve(r);
        });
      });
    };
  },
  deleteEventFromStoreOnly(event) {
    return function (dispatch) {
      return new Promise((resolve) => {
        const index = store.getState().calendar.eventsFromDB.findIndex((ev) => {
          if (+ev.ID === +event.ID) { return true; }
          return false;
        });
        dispatch({ type: 'DELETE_EVENT', index });
      });
    };
  },
};

function _convertEvents(baseEvents, className, tz, stacks, selectedEvent) {
  return baseEvents
    .filter(e => (!e.status || e.status === 'finished') &&
      (!e.end_ts || moment().tz(tz) > moment.tz(e.end_ts, tz)))
    .map((event, indexEvent) => {

      let isLog = false;
      if (event.start_commands) {
        isLog = true;
        event.startCommands = event.start_commands;
        event.stopCommands = event.stop_commands;
      }

      let title = '';
      const startCmds = event.startCommands ?
        event.startCommands.reduce((p, c) => c ? (`${p ? `${p}<br>` : ''}↗${c}`) : p, '') : '';
      title += `<div>${startCmds}</div>`;

      let stack = '';
      if (event.stack) { stack += `☰${stacks[event.stack]}`; }
      if (event.stack_name) { stack += `☰${event.stack_name}`; }
      title += `<div>${stack}</div>`;

      const stopCmds = event.stopCommands ?
        event.stopCommands.reduce((p, c) => c ? (`${p ? `${p}<br>` : ''}↘${c}`) : p, '') : '';
      title += `<div>${stopCmds}</div>`;

      if (isLog) {
        return {
          id: event.event_id,
          title: title,
          start: moment(event.start_ts).valueOf(),
          end: moment(event.end_ts).valueOf(),
          className: `event-from-log eventEMS-log-${event.event_id} ${selectedEvent === event.event_id && 'event-editing'}`,
          backgroundColor: 'gray',
          rendering: 'event',
          status: event.status,
          isLog,
        };
      } else {
        return {
          id: event.ID,
          title: title,
          start: event.start ? event.start.valueOf() : '',
          end: event.stop ? event.stop.valueOf() : '',
          className: `${className} eventEMS-${event.ID}`,
          backgroundColor: moment.tz(event.start, tz) < moment().tz(tz) &&
            moment().tz(tz) > moment.tz(event.stop, tz) ? 'gray' : event.color,
          rendering: 'event',
          status: event.status,
          isLog,
        };
      }
    });
}