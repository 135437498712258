import { bindActionCreators, createStore, applyMiddleware } from 'redux';
import { connect } from 'react-redux';
import rootReducer from './reducers';
import thunk from 'redux-thunk';

import {authorization} from './actionCreators/authorization';
import {control} from './actionCreators/control';
import {eventSubscribtion} from './actionCreators/eventSubscribtion';
import {messagesAlertsWarnings} from './actionCreators/messagesAlertsWarnings';
import {otherActions} from './actionCreators/otherActions';
import {pcsAndRack} from './actionCreators/pcsAndRack';
import {site} from './actionCreators/site';
import {pv} from './actionCreators/pv';
import {units} from './actionCreators/units';
import {inverters} from './actionCreators/inverters';
import {vpps} from './actionCreators/vpps';
import {admin} from './actionCreators/admin';
import {calendar} from './actionCreators/calendar';
import {config} from './actionCreators/config';
import {kinesis} from './actionCreators/kinesis';
import * as notifications from './actionCreators/notifications';

const store = createStore(
  rootReducer,
  applyMiddleware(thunk)
);
export {store};

export function dispatcher(type, payload) {
  return {
    type,
    ...payload
  };
}

const history = store.getState().service.history;
store.dispatch(dispatcher('SET_CURRENT_PATH'));
history.listen(() => {
  store.dispatch(dispatcher('SET_CURRENT_PATH'));
});

export const actionCreators = {...authorization, ...control, ...eventSubscribtion, ...calendar,
  ...config, ...messagesAlertsWarnings, ...otherActions, ...pcsAndRack, ...site, ...units,
  ...inverters, ...vpps, ...admin, ...notifications, ...pv, ...kinesis};

function mapDispatchToProps(dispatch) {
  return {actions: bindActionCreators(actionCreators, dispatch)};
}

export const storeConnector = (comp, data) => {
  const mapStateToProps = (state, ownProps) => {
  let usedFields = Object.keys(data).reduce((accumulator, currentValue)=>{
    if(data[currentValue] !== 'all'){
      const partialState = data[currentValue].reduce((acc, curValue) => {
        acc[curValue] = state[currentValue][curValue];
        return acc;
      }, {});
      return {...accumulator, ...partialState};
    } else {
      return {...accumulator, ...state[currentValue]};
    }
  },{});
  return {...usedFields, ...ownProps};
  };
  return connect(mapStateToProps, mapDispatchToProps)(comp);
};