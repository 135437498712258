import React from 'react';
import { Component } from 'react';
class SupportPopup extends Component {

  render() {
    return (
      <div className='cover-conteiner'>
        <div className='control-popup-container'>
          <div className='title-profile'>Fractal Operations Center</div>
          <div className='popup-close' onClick={()=>this.props.close()} />
          <div className='profile-content' style={{minWidth:'350px'}}>
            <div>Phone</div>
            <div>+1 (512) 646-9689</div>
            <div>Email</div>
            <div>operations@fractalems.com</div>
          </div>
        </div>
      </div>
    );
  }
}

export default SupportPopup;
